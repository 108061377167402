<template>
        <div class="map-container" ref="mapContainer"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from 'leaflet';
import {PHFB_ICON_PARAMS_BIG_A, PHFB_ICON_PARAMS_BIG_B, PHFB_ICON_PARAMS_BIG_C, PHFB_ICON_PARAMS_BIG_D, PHFB_ICON_PARAMS_BIG_E, PHFB_ICON_PARAMS_SMALL_A, 
    PHFB_ICON_PARAMS_SMALL_B, PHFB_ICON_PARAMS_SMALL_C, PHFB_ICON_PARAMS_SMALL_D, PHFB_ICON_PARAMS_SMALL_E,
    ESTIMATED_PHFB_ICON_PARAMS_SMALL_A, ESTIMATED_PHFB_ICON_PARAMS_SMALL_B, ESTIMATED_PHFB_ICON_PARAMS_SMALL_C, ESTIMATED_PHFB_ICON_PARAMS_SMALL_D, ESTIMATED_PHFB_ICON_PARAMS_SMALL_E, 
    ESTIMATED_PHFB_ICON_PARAMS_BIG_A, ESTIMATED_PHFB_ICON_PARAMS_BIG_B, ESTIMATED_PHFB_ICON_PARAMS_BIG_C, ESTIMATED_PHFB_ICON_PARAMS_BIG_D, ESTIMATED_PHFB_ICON_PARAMS_BIG_E, 
    IC_APEX_ICON_PARAMS_SMALL, IC_APEX_ICON_PARAMS_BIG} from '@/constants/constants.js'

export default {
    name: 'Map',
    components: {
        
    },
    data() {
        return {
            center: [44.007614262352945, 4.691924328117646],// TOTO: init map center with lat/long of territory

            // measure phfb icon 
            phfbIcon_A: L.icon(PHFB_ICON_PARAMS_SMALL_A),
            phfbIcon_B: L.icon(PHFB_ICON_PARAMS_SMALL_B),
            phfbIcon_C: L.icon(PHFB_ICON_PARAMS_SMALL_C),
            phfbIcon_D: L.icon(PHFB_ICON_PARAMS_SMALL_D),
            phfbIcon_E: L.icon(PHFB_ICON_PARAMS_SMALL_E),

            phfbIconBig_A: L.icon(PHFB_ICON_PARAMS_BIG_A),
            phfbIconBig_B: L.icon(PHFB_ICON_PARAMS_BIG_B),
            phfbIconBig_C: L.icon(PHFB_ICON_PARAMS_BIG_C),
            phfbIconBig_D: L.icon(PHFB_ICON_PARAMS_BIG_D),
            phfbIconBig_E: L.icon(PHFB_ICON_PARAMS_BIG_E),

            // estimation phfb icon 
            estimatedPhfbIcon_A: L.icon(ESTIMATED_PHFB_ICON_PARAMS_SMALL_A),
            estimatedPhfbIcon_B: L.icon(ESTIMATED_PHFB_ICON_PARAMS_SMALL_B),
            estimatedPhfbIcon_C: L.icon(ESTIMATED_PHFB_ICON_PARAMS_SMALL_C),
            estimatedPhfbIcon_D: L.icon(ESTIMATED_PHFB_ICON_PARAMS_SMALL_D),
            estimatedPhfbIcon_E: L.icon(ESTIMATED_PHFB_ICON_PARAMS_SMALL_E),

            estimatedPhfbIconBig_A: L.icon(ESTIMATED_PHFB_ICON_PARAMS_BIG_A),
            estimatedPhfbIconBig_B: L.icon(ESTIMATED_PHFB_ICON_PARAMS_BIG_B),
            estimatedPhfbIconBig_C: L.icon(ESTIMATED_PHFB_ICON_PARAMS_BIG_C),
            estimatedPhfbIconBig_D: L.icon(ESTIMATED_PHFB_ICON_PARAMS_BIG_D),
            estimatedPhfbIconBig_E: L.icon(ESTIMATED_PHFB_ICON_PARAMS_BIG_E),

            icApexIcon: L.icon(IC_APEX_ICON_PARAMS_SMALL),
            icApexIconBig: L.icon(IC_APEX_ICON_PARAMS_BIG),
            mapMarkers: [],// displayable markers on the map ()
            map: null
        }
    },
    props: {
        phfb_markers: Array, // array of Markers (customised class)
        ic_apex_markers: Array, 
        display_phfb: String, // layers to show on map
        display_ic_apex: String,
        right_side_bar_cross_closing_click: Number
    },
    methods: {
        // set up map
        setUpMap() {
            // set map layer
            this.map = L.map(this.$refs.mapContainer,).setView(this.center, 13); // {zoomAnimation:false,} -> if needed: to avoid "Cannot read properties of null (reading '_latLngToNewLayerPoint')" error
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {attribution: '© OpenStreetMap contributors',})
                .addTo(this.map);
            this.map.on('click', (e) => {
                this.$emit('map-clicked');
                this.unselectAllMarkers();
            });
        },
        // add markers to the map (markers are hiden by default)
        addMarkers() {
            // remove all existing markers from map
            this.removeMapMarkers();
            this.addPHFBMarkers();
            this.addICAPEXMarkers();
        },
        // add phfb markers to map
        addPHFBMarkers() {
            for (let i = 0; i < this.phfb_markers.length; i++){
                // console.log('added marker:', this.phfb_markers[i]);
                // add marker to map
                // get phfb icon according to constraint class
                const phfb_icon = this.getPHFBIcon(this.phfb_markers[i].constraint_class, this.phfb_markers[i].measure_type);
                
                const map_marker = L.marker(L.latLng(parseFloat(this.phfb_markers[i].latitude), parseFloat(this.phfb_markers[i].longitude)), {icon: phfb_icon}).addTo(this.map);
                if (this.phfb_markers[i].measure_type == null) {
                    // console.log('this.phfb_markers[i].measure_type: ', this.phfb_markers[i].measure_type);
                    // console.log('map_marker: ', map_marker);
                } 
                // set marker click listener
                map_marker.addEventListener('click', (e) => {
                    // if ctrl key wasn't pressed -> unselect all other markers
                    if (!e.originalEvent.ctrlKey) {
                        this.unselectAllMarkers();
                    }
                    // select marker clicked + send event
                    const phfb_icon_big = this.getPHFBIconBig(this.phfb_markers[i].constraint_class, this.phfb_markers[i].measure_type);
                    // map_marker.setIcon(this.phfb_markers[i].measure_type == 'estimation' ? this.estimatedPhfbIconBig : phfb_icon_big);
                    map_marker.setIcon(phfb_icon_big);
                    this.$emit('marker-clicked', 'phfb', this.phfb_markers[i].id, e);
                    this.tagMarkerAsClicked(map_marker._leaflet_id);
                });
                // set marker hover listener
                map_marker.addEventListener('mouseover', () => {
                    const phfb_icon_big = this.getPHFBIconBig(this.phfb_markers[i].constraint_class, this.phfb_markers[i].measure_type);
                    map_marker.setIcon(phfb_icon_big);
                });
                //set marker mouseout listener
                map_marker.addEventListener('mouseout', () => {
                    // check if marker is not already clicked
                    if (!this.isMarkerClicked(map_marker._leaflet_id)){
                        const phfb_icon = this.getPHFBIcon(this.phfb_markers[i].constraint_class, this.phfb_markers[i].measure_type);
                        map_marker.setIcon(phfb_icon);
                    }
                });
                // register marker in mapMarker list
                this.mapMarkers.push([map_marker, 'not-clicked', this.phfb_markers[i].constraint_class]);
            }
            // set map view according to marker locations
            if (this.phfb_markers.length > 0) {
                this.center = this.getAvrgLatLongMarkers(this.phfb_markers);
                this.map.setView(this.center,13);
                // console.log('view ', this.center);
            }
        },
        // add ic-apex markers to map
        addICAPEXMarkers() {
            for (let i = 0; i < this.ic_apex_markers.length; i++){
                // add marker to map
                //const map_marker = L.marker([this.ic_apex_markers[i].latitude, this.ic_apex_markers[i].longitude], {icon: this.icApexIcon}).addTo(this.map);
                const map_marker = L.marker(L.latLng(parseFloat(this.ic_apex_markers[i].latitude), parseFloat(this.ic_apex_markers[i].longitude)), {icon: this.icApexIcon}).addTo(this.map);
                // set marker click listener
                map_marker.addEventListener('click', (e) => {
                    // if ctrl key wasn't pressed -> unselect all other markers
                    if (!e.originalEvent.ctrlKey) {
                        this.unselectAllMarkers();
                    }
                    // select marker clicked + send event
                    map_marker.setIcon(this.icApexIconBig);
                    this.$emit('marker-clicked', 'ic-apex', this.ic_apex_markers[i].id, e);
                    this.tagMarkerAsClicked(map_marker._leaflet_id);
                });
                // set marker hover listener
                map_marker.addEventListener('mouseover', () => {
                    map_marker.setIcon(this.icApexIconBig);
                });
                // set marker mouseout listener
                map_marker.addEventListener('mouseout', () => {
                    // check if marker is not already clicked
                    if (!this.isMarkerClicked(map_marker._leaflet_id)){
                        map_marker.setIcon(this.icApexIcon);
                    }
                });
                // register marker in mapMarker list
                this.mapMarkers.push([map_marker, 'not-clicked', this.phfb_markers[i].constraint_class]);
            }
        },
        // remove all markers from the map
        removeMapMarkers() {
            // remove markers from map
            this.mapMarkers.forEach((mapMarker) => {
                if (mapMarker) {
                    this.map.removeLayer(mapMarker[0]);// mapMarker is of the form [marker,'not-clicked']
                }
            });
            // reset markersMap list
            while (this.mapMarkers.length > 0){
                this.mapMarkers.pop();
            }
        },
        // tells if the marker of the specific ID is clicked (useful for check in mouseout event)
        isMarkerClicked(id) {
            // find marker with the matching id inside the mapMarkers list
            const marker = this.mapMarkers.find((mapMarker) => {
                return mapMarker[0]._leaflet_id == id;// mapMarker looks like: [marker, 'clicked'/'not-clicked']
            })
            // check if marker is clicked
            if (marker[1] === 'clicked') {
                return true;
            }
            return false;
        },
        // tags the marker of the specified ID as 'clicked'
        tagMarkerAsClicked(id) {
            // find marker with the matching id inside the mapMarkers list
            for (var i = 0; i < this.mapMarkers.length ; i++) {
                if (this.mapMarkers[i][0]._leaflet_id === id) {
                    // tag marker as clicked
                    this.mapMarkers[i][1] = 'clicked';
                }
            }
        },
        // tags all markers as 'not-clicked' + sets their icon's size back to normal size
        unselectAllMarkers() {
            for (var i = 0; i < this.mapMarkers.length ; i++) {
                // tag marker as not-clicked
                this.mapMarkers[i][1] = 'not-clicked';
                // get marker type
                const marker_type = this.mapMarkers[i][0]._icon.classList[1];
                // set its icon's size back to normal (according to its type)
                let phfb_icon;
                switch (marker_type) {
                    case 'phfb-icon':
                        // get phfb icon according to constraint class
                        phfb_icon = this.getPHFBIcon(this.mapMarkers[i][2], 'measure');
                        this.mapMarkers[i][0].setIcon(phfb_icon);
                    break;
                    case 'estimated-phfb-icon':
                        phfb_icon = this.getPHFBIcon(this.mapMarkers[i][2], 'estimation');
                        this.mapMarkers[i][0].setIcon(phfb_icon);
                    break;
                    case 'ic-apex-icon':
                        this.mapMarkers[i][0].setIcon(this.icApexIcon);
                    break;
                }                
            }
        },
        // process average latitude and longitude of a set of markers
        getAvrgLatLongMarkers(markers){
            let avrgLat = 0;
            let avrgLong = 0;
            markers.forEach((marker) => {
                avrgLat += Number(marker.latitude); 
                avrgLong += Number(marker.longitude);
            });
            avrgLat = avrgLat/markers.length;
            avrgLong = avrgLong/markers.length;
            return [avrgLat, avrgLong];
        },
        getPHFBIcon(constraint_class, measure_type) {
            if (constraint_class === 'A') {
                    return measure_type === 'estimation' ? this.estimatedPhfbIcon_A : this.phfbIcon_A;
            } else {
                if (constraint_class === 'B') {
                    return measure_type === 'estimation' ? this.estimatedPhfbIcon_B : this.phfbIcon_B;
                } else {
                    if (constraint_class === 'C') {
                        return measure_type === 'estimation' ? this.estimatedPhfbIcon_C : this.phfbIcon_C;
                    } else {
                        if (constraint_class === 'D') {
                            return measure_type === 'estimation' ? this.estimatedPhfbIcon_D : this.phfbIcon_D;
                        } else {
                            return measure_type === 'estimation' ? this.estimatedPhfbIcon_E : this.phfbIcon_E;
                        }
                    }
                }
            }
        },
        getPHFBIconBig(constraint_class, measure_type) {
            if (constraint_class === 'A') {
                return measure_type === 'estimation' ? this.estimatedPhfbIconBig_A : this.phfbIconBig_A;                    
            } else {
                if (constraint_class === 'B') {
                    return measure_type === 'estimation' ? this.estimatedPhfbIconBig_B : this.phfbIconBig_B;
                } else {
                    if (constraint_class === 'C') {
                        return measure_type === 'estimation' ? this.estimatedPhfbIconBig_C : this.phfbIconBig_C;
                    } else {
                        if (constraint_class === 'D') {
                            return measure_type === 'estimation' ? this.estimatedPhfbIconBig_D : this.phfbIconBig_D;
                        } else {
                            return measure_type === 'estimation' ? this.estimatedPhfbIconBig_E : this.phfbIconBig_E;
                        }
                    }
                }
            }
        }
    },
    watch: {
        // to listen to changes in ic_apex_markers changes
        ic_apex_markers() {
            //this.addICAPEXMarkers();
            this.addMarkers();
        },
        phfb_markers() {
            this.addMarkers();
        },
        // whenever the value of this props changes the callback function is triggered
        // this allows to emit an event from the parent component (MapView) to here (Map child component) when the right_side_bar closing button is clicked
        right_side_bar_cross_closing_click(value) {
            this.unselectAllMarkers();
        }
    },
    mounted() {
        this.setUpMap();
        this.addMarkers();
        // console.log('phfb markers in map: ', this.phfb_markers);
    },
}
</script>

<style>
    div.map-container {
        position: fixed;
        left: 20vw;
        top:6vh;
        width: 80vw;
        height: 94vh;
        background-color: #5c7778;
        z-index: 0;
    }
    .phfb-icon{
        display: v-bind('display_phfb');
        
    }
    .estimated-phfb-icon{
        display: v-bind('display_phfb');
        opacity: 0.8;
    }
    .ic-apex-icon{
        display: v-bind('display_ic_apex');
    }
</style>