import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// Vue.config.productionTip = false;

// Vue.use(VModal, {dialog: true});

createApp(App).use(router).mount('#app')

// new Vue({
//     render: (h) => h(App),
//   }).$mount('#app');


