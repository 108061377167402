<template>
        <div class="container" >
                <div v-if="showListParcelsView">
                    <transition name="slide">
                        <div>
                            <h1 class="title">Mes Parcelles</h1>
                            <div class="scrollable-list">
                                <ul>
                                    <li v-for="parcel in parcels" :key="parcel.id"  @dblclick="parcelDblClicked">
                                            <div class="parcel-name" @click="parcelClicked" :id="parcel.id">{{ parcel.name }}</div>
                                            <div v-if="parcel.butonsVisible == true" class="parcel-buttons" :ref=" parcel.id">
                                            </div>     
                                    </li>
                                </ul>
                            </div>
                        <!-- <button class="button" @click="addParcelButtonClicked"> + </button> -->
                    </div>
                    </transition>
                </div>
            <div v-else>
                <Parcel :user="user" v-bind:selectedParcelName="selectedParcelName" @close-parcel-details="closeParcelDetails"></Parcel>
            </div>
            <div v-if="isDataLoading">
                <LoadingSpinner></LoadingSpinner>
            </div>
        </div>
</template>


<script>

import LoadingSpinner from "@/components/animatedComponents/LoadingSpinner.vue"
import * as backend_services from "@/services/backend_services";
import Parcel from "@/components/configComponents/Parcel.vue";

export default{
    data () {
        return {
            showListParcelsView: true,
            isDataLoading: false,
            parcels: [],
            selectedParcelName: null,
            display_parcel_buttons:'none',
        }
    },
    components: {
        LoadingSpinner,
        Parcel
    },
    props: {
        user: String,
        parcelToShow:String,
    },
    methods:{
        // TODO: also get the parcel names involved in ic-apex measures in a second version
        // get all parcel names involved in ic-apex and phfb measures
        async getParcels(){
            this.isDataLoading = true;
            this.parcels = await backend_services.getParcelNames(); 
            // turns the parcels list into a list of parcel dictionnaries of the form (id: parcel.id, name: parcel.name, butonsVisible: true/false)
            // rq: done in order to handle parcels buttons (details, suppression) display
            let parcelsTemp = [];
            this.parcels.forEach((parcel) => {
                parcelsTemp.push({'id':parcel.id, 'name': parcel.name, 'butonsVisible': false})
            });
            this.parcels = parcelsTemp;
            this.isDataLoading = false;
            console.log('nb parcels: ', this.parcels.length);
        },
        parcelClicked(e){
            // get clicked parcel name
            // this.selectedParcelName = e.target.textContent;
            this.displayParcel(e.target.textContent);
            // get clicked parcel id
            // this.selectedParcelId = e.target.id;
            // hide all other parcels' buttons
            // this.parcels.forEach((parcel) => parcel.butonsVisible = false);
            // display selected parcel's buttons
            // const parcel = this.parcels.find((parcel) => parcel.id == this.selectedParcelId);
            // parcel.butonsVisible = true;
            // const parcelBis = this.parcels.find((parcel) => parcel.id == this.selectedParcelId);
            // this.showListParcelsView = false;
        },
        closeParcelDetails(){
            this.showListParcelsView = true;
        },
        // display the specified parcel
        displayParcel(parcelName){
            this.showListParcelsView = false;
            this.selectedParcelName = parcelName;
        }
    },
    created(){
        this.getParcels().then(() => {
            if (this.parcelToShow != null) {
                this.selectedParcelName = this.parcelToShow;
                this.displayParcel(this.parcelToShow);
            }
        });
    }
}
</script>

<style scoped>
    div.container {
        position: fixed;
        left: 20vw;
        top:6vh;
        width: 80vw;
        height: 94vh;
        background-color: hsl(180, 30%, 89%);
        z-index: 0;
    }
    .listContainer {
        position: absolute;
        display: flex;
        top: 5%;
        left: 5%;
    }
    .dropDownContainer{
        position: absolute;
        top: 10%;
        left: 5%;
    }
    div.parcel-name{
        list-style-type: none;
        margin: 10px 5px;
        padding: 2% 0;
        text-align: center;
        background-color: #9fb6c9;
        border-radius: 5%;
    }
    div.parcel-buttons{
        text-align: center;
    }
    .scrollable-list {
        position: absolute;
        top: 10%;
        left: 37%;
        height: 200px; /* Set the desired height for your scrollable list */
        width: 30%;
        overflow-y: auto; /* Enable vertical scrolling */
        background-color: hsl(180, 19%, 81%);
        padding: 0;
        border-radius: 5%;
        /* border: 1px solid #ccc; /* Add a border or customize styles as needed */
    }
    div.parcel-name:hover {
        cursor: pointer; /* Change the cursor to a pointer when hovering over the items to indicate they are clickable */
        background-color: gray;
    }
    .title{
        position: absolute;
        top: 2%;
        left: 45%;
    }
    .button{
        position: absolute;
        top: 33%;
        left: 37%;
        width: 2.5%;
        height: 4%;
        font-size:large;
    }
    .button:hover{
        cursor: pointer;
    }

    ul{
        padding: 0;
    }

    /* Scroll bar stylings */
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--lightestgrey); 
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
</style>