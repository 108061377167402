<template>
    <div class="container" >
        <span class="backArrowButton" @click="closeParcelDetails"> &#x25c0; </span>
        <h1>{{ this.selectedParcelName }}</h1>
        <div >
            <table class="measures-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Valeur</th>
                        <th>Unité</th>
                        <th>Type donnée</th>
                        <th>Confiance</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="measure in this.measures" :key="measure.id">
                        <td>{{ measure.date }}</td>
                        <td>{{ measure.type }}</td>
                        <td>{{ parseFloat(measure.measure_value.replace(',','.')).toFixed(2) }}</td>
                        <td>{{ measure.unit }}</td>
                        <td>{{ measure.measure_type == null ? 'mesure' : 'estimation' }}</td>
                        <td>{{ measure.reliability == 'high' ? 'haute' : measure.reliability == 'medium' ? 'moyenne' : 'faible' }}</td>
                    </tr>
                </tbody>
            </table>
            <button v-if="user=='technician'" class="addMeasureButton" @click="openFormWindow"> + </button>
        </div>
        <!-- <li v-for="(item, index) in seasons" :key="index" @change="checkboxListClicked">
                        <input type="checkbox" :value="item"/>
                        {{ item }}
        </li> -->
        <!-- <div class="chart" v-if="isDataLoading === false"><LineChart :phfbMeasures="phfbMeasures" :icapexMeasures="icapexMeasures"></LineChart></div> -->
        <div class="chart" v-if="isDataLoading === false"><LineChart :phfbMeasures_2021="phfbMeasures_2021" :phfbMeasures_2022="phfbMeasures_2022" :phfbMeasures_2023="phfbMeasures_2023"></LineChart></div>
        <FormWindow v-if="isFormWindowVisible" @close="closeWindowModal" @close-window-form="closeFormWindow" v-bind:selectedParcel="selectedParcelName"> </FormWindow>
        <div v-if="isDataLoading">
                <LoadingSpinner></LoadingSpinner>
        </div>
    </div>
</template>


<script>
import * as db_interaction from '@/services/db_interaction'
import LoadingSpinner from "@/components/animatedComponents/LoadingSpinner.vue"
import FormWindow from './FormWindow.vue'
import LineChart from '@/components/configComponents/LineChart.vue'
import * as date_functions from '@/services/date_functions'


export default{

components: {
    LoadingSpinner,
    FormWindow,
    LineChart
},
data(){
    return {
        measures: [],
        phfbMeasures_2021:[],
        phfbMeasures_2022:[],
        phfbMeasures_2023:[],
        icapexMeasures: [{x:"01/13/2009",y:0.56}, {x:"01/25/2009",y:0.67}, {x:"06/05/2010", y:0.85}],
        isDataLoading: false,
        isFormWindowVisible: false,
        seasons:[],
    }
},
props: {
    selectedParcelName: String,
    user: String
},
methods:{
        async getSeasonsPHFB(){
            this.isDataLoading = true;
            console.log('test');
            // request seasons
            this.seasons = await db_interaction.getSeasonsPHFBParcel(this.selectedParcelName); // TODO: test and verify this function
            console.log('laaaaaa');
            this.isDataLoading = false;
            console.log('this.seasons: ', this.seasons);
        },
        async getParcelMeasures(){
            this.isDataLoading = true;
            // get PHFB measures
            this.measures = await db_interaction.getMeasuresParcel(this.selectedParcelName);
            this.getMeasuresDataChart(this.measures);
            // TODO: get ic-apex measures
            this.isDataLoading = false;
        },
        openFormWindow(){
            this.isFormWindowVisible = true;
        },
        closeFormWindow(){
            this.isFormWindowVisible = false;
        },
        closeParcelDetails() {
            this.$emit('close-parcel-details')
        },
        // process phfb and icapex measures in the form 
        // [{x:"01/13/2009",y:0.56}, {x:"01/25/2009",y:0.67}, {x:"06/05/2010", y:0.85}]
        // note: this method is used to process data to plot into the chart
        getMeasuresDataChart(measures){
            measures.forEach((measure) => {
                console.log('annee: ', measure.annee);
                if (measure.type === 'PHFB'){
                    if (measure.annee === '2021') {
                        this.phfbMeasures_2021.push({x:date_functions.changeStringDateFormat(measure.date), y:parseFloat(measure.measure_value.replace(',','.'))})
                    } else if (measure.annee === '2022') {
                        this.phfbMeasures_2022.push({x:date_functions.changeStringDateFormat(measure.date), y:parseFloat(measure.measure_value.replace(',','.'))})
                    } else if (measure.annee === '2023') {
                        this.phfbMeasures_2023.push({x:date_functions.changeStringDateFormat(measure.date), y:parseFloat(measure.measure_value.replace(',','.'))})
                    }
                } 
                if (measure.type === 'IC-APEX'){
                    // todo: this.icapexMeasures.push( same as for phfb measures )
                }
            });
        },
    },
    created(){
        this.getParcelMeasures();
    },
    mounted(){
        // this.getSeasonsPHFB();
    }
}
</script>

<style scoped>
    h1{
        position: absolute;
        left: 45%;
    }
    div.container {
        position: fixed;
        left: 20vw;
        top:6vh;
        width: 80vw;
        height: 94vh;
        background-color: #b5cbce;
        z-index: 0;
        display:grid;
        flex-wrap: wrap;
    }

    td {
        text-align: center;
    }

    .measures-table {
        position: absolute;
        left: 30%;
        top: 10%;
        /* width: 50%; */
        /* border-collapse: collapse; */
        margin-top: 20px;
        max-height: 30%;
        overflow-y:scroll;
        height:50%;
        display:block;
        background-color: hsl(180, 19%, 81%);
        border-radius: 5px;
    }
    .measures-table th, .measures-table td {
        padding: 8px 10px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }
    .measures-table th {
        background-color: #96b2b3;
    }
    .measures-table tbody tr:hover {
        background-color: #f5f5f5;
        border-radius: 5px ;
    }
    .addMeasureButton {
        position: absolute;
        left: 30%;
        top: 45%;
        width: 40px;
        height: 4%;
        font-size:large;
    }
    .addMeasureButton:hover{
        cursor:pointer;
    }
    .backArrowButton {
        font-size: 40px;
        /* font-weight: bold; */
        width: 40px;
        height: 40px;
        display: inline-block;
        position: absolute;
        left: 2%;
        top: 2%;
        color:rgb(148, 151, 148);
    }
    .backArrowButton:hover{
        cursor: pointer;
        color:rgb(107, 110, 107);
        transform:scale(1.2);
    }

    .chart {
        position: absolute;
        left: 29%;
        top: 60%;
        /* width: 50%; */
        /* border-collapse: collapse; */
        /* margin-top: 20px;
        max-height: 30%; */
        /* overflow-y:scroll; */
        height:35%;
        width: 40%;
        display:block;
        background-color: hsl(180, 19%, 81%);
        border-radius: 5px;
    }

    /* Scroll bar stylings */
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: var(--lightestgrey); 
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888; 
        border-radius: 5px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
</style>