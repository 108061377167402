export function getLineChartData(phfbMeasures_2021, phfbMeasures_2022, phfbMeasures_2023) { 
    return {
        type: 'line',
        data: {
        // labels: phfbDates,//["Thu Aug 13 2009", "Fri Jun 25 2010", "Wed Jun 30 2010"], ['Tue Jun 13 2023'], 
        datasets: [
            {
                label: "2021",
                data: phfbMeasures_2021,//[{x:"08/13/2009",y:0.5}, {x:"01/25/2010",y:0.6}, {x:"02/05/2010", y:0.7}],//phfbValues, // [4]
                // [0, 0, 1, 2, 79, 82, 27, 14],
                backgroundColor: "rgba(54,73,93,.5)",
                borderColor: "#36495d",
                borderWidth: 3,
                hidden: true
            },
            {
                label: "2022",
                data: phfbMeasures_2022,
                backgroundColor: "rgba(71, 183,132,.5)",
                borderColor: "#47b784",
                borderWidth: 3,
                hidden: true
            },
            {
                label: "2023",
                data: phfbMeasures_2023,
                backgroundColor: "rgba(255, 99,71,.5)",
                borderColor: "#c12d18",
                borderWidth: 3
            }
        ]
        },
        options: {
            responsive: true,
            lineTension: 0,
            scales: {
                // yAxes: 
                // {
                //     ticks: {
                //     beginAtZero: true,
                //     padding: 25
                //     }
                // }
                // ,
                xAxes: 
                {
                    ticks: {
                        beginAtZero: true,
                        // padding: 25
                    },
                    type: "time",
                    time: {
                        unit: "month",
                    },
                }
            }
        }
  }
}