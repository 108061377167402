// const baseURL = `http://localhost/`;
const baseURL = 'https://mxv-back.servadmin.fr/';
// const baseURLAppEau = `http://appeau.api.vignevin-epicure.com/`;
// const baseURL = `http://mapx-vigne.agrotic.org/python`;
// const baseURL = `/python/`;


// login function
export const login = async (email, password) => {
  const formData = {
    email: email,
    password: password,
  };
  
  try{
    // fetch the data
    const res = await fetch(baseURL+ '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
    });
    
    if (!res.ok) {
      const message = 'error logging in';
      throw new Error(message);
    }
    
    const response = await res.json();
    return response;

    } catch(error) {
        console.error(error.message);    
    }
}

// upload file function
export const uploadCSVFile = async () => {
  const token = localStorage.getItem('token');
  try{
      // fetch the data
      const res = await fetch(baseURL + 'uploadCSVFile', {
          method: 'POST',
          headers: {
              'content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          }
      });
      if (!res.ok) {
        const message = 'Error';
        throw new Error(message);
      }
      
      const result = await res.json();

      return result;

      } catch(error) {
          console.error(error.message);
      }
};

// returns a list of all parcel names
export const getParcelNames = async () => {
    try{
        console.log("test");
        // fetch the data
        const res = await fetch(baseURL + `getParcelNames`);
        if (!res.ok) {
          const message = 'Error';
          throw new Error(message);
        }
        
        const parcelNames = await res.json();

        return parcelNames;

        } catch(error) {
            console.error(error.message);
        }
};


// returns a list of all parcel names
export const isSeasonValidPHFBModel = async () => {
  try{
      console.log("test");
      // fetch the data
      const res = await fetch(baseURL + `getParcelNamesPHFB`);
      if (!res.ok) {
        const message = 'Error';
        throw new Error(message);
      }
      
      const parcelNames = await res.json();

      // make an array of Measures
      // let measures = [];
      // observations.forEach((obs) => {
      //     // formatting date to mm/dd/yyyy
      //     const dateParts = obs.date.split("/");
      //     const date =  new Date(dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2]);
      //     measures.push(new Measure(parseInt(obs.id),obs.parcel_name, Number(obs.phfb_value.replace(",",".")), obs.measure_type, obs.type, date, obs.reliability, Number(obs.latitude.replace(",",".")), Number(obs.longitude.replace(",","."))));
      // });

      return parcelNames;

      } catch(error) {
          console.error(error.message);
      }
};

/*** appEau functions ***/

// get PHFB from appEau
// export const getPHFBFromAppEau = async (token) => {
//   try{
//     const formData = {
//       "parametresParcelle": {
//         "largeurInterRang": 4,
//         "hauteurFeuillage": 2.5,
//         "reserveUtile": 350,
//         "entretienCavaillonDebut": "enh",
//         "entretienInterRangDebut": "enh",
//         "dateModifEntretien": "01/01",
//         "entretienCavaillonFin": "enh",
//         "entretienInterRangFin": "enh",
//         "dateDebourrement": "01/04",
//         "orientationRang": "NS"
//       },
//       "data": [
//         {
//           "date": "2023-01-01",
//           "pluviometrieJour": 0,
//           "temperatureMoyJour": 0,
//           "etpJour": 0
//         },
//         {
//           "date": "2023-01-02",
//           "pluviometrieJour": 0,
//           "temperatureMoyJour": 0,
//           "etpJour": 0
//         },
//         {
//             "date": "2023-01-03",
//           "pluviometrieJour": 0,
//           "temperatureMoyJour": 0,
//           "etpJour": 0
//         },
//         {
//             "date": "2023-01-04",
//           "pluviometrieJour": 0,
//           "temperatureMoyJour": 0,
//           "etpJour": 0
//         },
//         {
//             "date": "2023-01-05",
//           "pluviometrieJour": 0,
//           "temperatureMoyJour": 0,
//           "etpJour": 0
//         },
//         {
//             "date": "2023-01-06",
//           "pluviometrieJour": 0,
//           "temperatureMoyJour": 0,
//           "etpJour": 0
//         },
//         {
//             "date": "2023-01-07",
//           "pluviometrieJour": 0,
//           "temperatureMoyJour": 0,
//           "etpJour": 0
//         }
//       ]
//     };
//     // fetch the data
//     const res = await fetch(baseURLAppEau + `api/calcul/direct`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `bearer ${token['token']}`
//         },
//         body: JSON.stringify(formData),
//     });
    
//     if (!res.ok) {
//       const message = 'Error';
//       throw new Error(message);
//     }
    
//     const bilan_hydrique = await res.json();

//     return bilan_hydrique;

//     } catch(error) {
//         console.error(error.message);
//     }
// };

// get token AppEau API
export const getTokenAppEau = async (email, password) => {
  try{
      const formData = {
        username: email,
        password: password,
      };
      // fetch the data
      const res = await fetch(baseURLAppEau + `auth`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
      });

      if (!res.ok) {
        const message = 'Error';
        throw new Error(message);
      }
      
      const token = await res.json();

      return token;

      } catch(error) {
          console.error(error.message);
      }
};

// upload file function
export const importInitialMeasures = async (refFile, satFile) => {
  const token = localStorage.getItem('token');
  try{
    const formData = new FormData();
    formData.append('refFile', refFile);
    formData.append('satFile', satFile);
      // fetch the data
      const res = await fetch(baseURL + 'importInitialMeasures', {
          method: 'POST',
          headers: {
              // 'content-Type': 'application/csv',
              'Authorization': `Bearer ${token}`
          },
          body: formData
      });
      if (!res.ok) {
        const message = 'Error';
        throw new Error(message);
      }
      
      const result = await res.json();

      return result;

      } catch(error) {
          console.error(error.message);
      }
};

// observations upload function :  reprendre ici 
export const importNewMeasures = async (newMeasuresFile) => {
  const token = localStorage.getItem('token');
  try{
    const formData = new FormData();
    formData.append('newMeasuresFile', newMeasuresFile);
      // fetch the data
      const res = await fetch(baseURL + 'importNewMeasures', {
          method: 'POST',
          headers: {
              // 'content-Type': 'application/csv',
              'Authorization': `Bearer ${token}`
          },
          body: formData
      });
      if (!res.ok) {
        const message = 'Error';
        throw new Error(message);
      }
      
      const result = await res.json();

      return result;

      } catch(error) {
          console.error(error.message);
      }
};

// export const computeSpiderCoefficients = async (measuresFile, parcelsType) => {
//   const token = localStorage.getItem('token');
//   let apiFunctionName = null;
//   try{
//     const formData = new FormData();
//     formData.append('measuresFile', measuresFile);
//       // fetch the data
//       const res = await fetch(baseURL + 'computeSpiderCoefficientsBis', {
//           method: 'POST',
//           headers: {
//               // 'content-Type': 'application/csv',
//               'Authorization': `Bearer ${token}`
//           },
//           body: formData
//       });
//       if (!res.ok) {
//         const message = 'Error';
//         throw new Error(message);
//       }
      
//       const result = await res.json();

//       return result;

//       } catch(error) {
//           console.error(error.message);
//       }
// };



