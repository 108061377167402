// export const PHFB_ICON_PARAMS_SMALL = {
//     iconUrl: 'images/phfb.svg', // Path relative to your public directory
//     iconSize: [25, 40],
//     iconAnchor: [12.5, 40],
//     popupAnchor: [1, -34],
//     className: 'phfb-icon'
// }

/**********************************************/
/****************** PHFB ICONS ****************/
/**********************************************/

// SMALL 

export const PHFB_ICON_PARAMS_SMALL_A = {
    iconUrl: 'images/phfb_A.svg', // Path relative to your public directory
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}

export const PHFB_ICON_PARAMS_SMALL_B = {
    iconUrl: 'images/phfb_B.svg', 
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}

export const PHFB_ICON_PARAMS_SMALL_C = {
    iconUrl: 'images/phfb_C.svg', 
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}

export const PHFB_ICON_PARAMS_SMALL_D = {
    iconUrl: 'images/phfb_D.svg', 
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}

export const PHFB_ICON_PARAMS_SMALL_E = {
    iconUrl: 'images/phfb_E.svg', 
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}


// BIG 

export const PHFB_ICON_PARAMS_BIG_A = {
    iconUrl: 'images/phfb_A.svg', // Path relative to your public directory
    iconSize: [33, 54],
    iconAnchor: [16.5, 54],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}

export const PHFB_ICON_PARAMS_BIG_B = {
    iconUrl: 'images/phfb_B.svg', 
    iconSize: [33, 54],
    iconAnchor: [16.5, 54],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}

export const PHFB_ICON_PARAMS_BIG_C = {
    iconUrl: 'images/phfb_C.svg', 
    iconSize: [33, 54],
    iconAnchor: [16.5, 54],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}

export const PHFB_ICON_PARAMS_BIG_D = {
    iconUrl: 'images/phfb_D.svg', 
    iconSize: [33, 54],
    iconAnchor: [16.5, 54],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}

export const PHFB_ICON_PARAMS_BIG_E = {
    iconUrl: 'images/phfb_E.svg', 
    iconSize: [33, 54],
    iconAnchor: [16.5, 54],
    popupAnchor: [1, -34],
    className: 'phfb-icon'
}

export const ESTIMATED_PHFB_ICON_PARAMS_SMALL_A = {
    iconUrl: 'images/estimated_phfb_A.svg', // Path relative to your public directory
    iconSize: [25, 40],
    iconAnchor: [12.5, 40],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}
export const ESTIMATED_PHFB_ICON_PARAMS_SMALL_B = {
    iconUrl: 'images/estimated_phfb_B.svg', // Path relative to your public directory
    iconSize: [25, 40],
    iconAnchor: [12.5, 40],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}

export const ESTIMATED_PHFB_ICON_PARAMS_SMALL_C = {
    iconUrl: 'images/estimated_phfb_C.svg', // Path relative to your public directory
    iconSize: [25, 40],
    iconAnchor: [12.5, 40],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}

export const ESTIMATED_PHFB_ICON_PARAMS_SMALL_D = {
    iconUrl: 'images/estimated_phfb_D.svg', // Path relative to your public directory
    iconSize: [25, 40],
    iconAnchor: [12.5, 40],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}

export const ESTIMATED_PHFB_ICON_PARAMS_SMALL_E = {
    iconUrl: 'images/estimated_phfb_E.svg', // Path relative to your public directory
    iconSize: [25, 40],
    iconAnchor: [12.5, 40],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}

export const ESTIMATED_PHFB_ICON_PARAMS_BIG_A = {
    iconUrl: 'images/estimated_phfb_A.svg', // Path relative to your public directory
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}

export const ESTIMATED_PHFB_ICON_PARAMS_BIG_B = {
    iconUrl: 'images/estimated_phfb_B.svg', // Path relative to your public directory
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}

export const ESTIMATED_PHFB_ICON_PARAMS_BIG_C = {
    iconUrl: 'images/estimated_phfb_C.svg', // Path relative to your public directory
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}

export const ESTIMATED_PHFB_ICON_PARAMS_BIG_D = {
    iconUrl: 'images/estimated_phfb_D.svg', // Path relative to your public directory
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}

export const ESTIMATED_PHFB_ICON_PARAMS_BIG_E = {
    iconUrl: 'images/estimated_phfb_E.svg', // Path relative to your public directory
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}

// export const PHFB_ICON_PARAMS_BIG = {
//     iconUrl: 'images/phfb_selected.svg',
//     iconSize: [31, 50],
//     iconAnchor: [15.5, 50],
//     popupAnchor: [1, -34],
//     className: 'phfb-icon'
// }

export const ESTIMATED_PHFB_ICON_PARAMS_BIG = {
    iconUrl: 'images/estimated_phfb_selected.svg', 
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'estimated-phfb-icon'
}


/**********************************************/
/****************** IC-APEX ICONS ****************/
/**********************************************/

export const IC_APEX_ICON_PARAMS_SMALL = {
    iconUrl: 'images/apex.svg', // Path relative to your public directory
    iconSize: [25, 40],
    iconAnchor: [12.5, 40],
    popupAnchor: [1, -34],
    className: 'ic-apex-icon'
}

export const IC_APEX_ICON_PARAMS_BIG = {
    iconUrl: 'images/apex_selected.svg', // Path relative to your public directory
    iconSize: [31, 50],
    iconAnchor: [15.5, 50],
    popupAnchor: [1, -34],
    className: 'ic-apex-icon'
}

