<template>
  <div class="home">
    <Map @marker-clicked="markerClicked" v-bind:phfb_markers="phfb_markers" v-bind:ic_apex_markers="ic_apex_markers"
    v-bind:display_phfb="display_phfb" v-bind:display_ic_apex="display_ic_apex" @map-clicked="mapClicked" v-bind:right_side_bar_cross_closing_click="right_side_bar_cross_closing_click"/>
    <LeftSideBar :existingSpiderModel="existingSpiderModel" @new-ref-parcel-bilan-hydrique="newRefParcelBilanHydrique" @new-ref-parcel-measure="newRefParcelMeasure" :refParcel="refParcel" v-show="leftSideBarMap" @checkbox-clicked="checkboxLayerClicked" @toggle-tab-button="toggleTabButton" @menu-button-clicked="menuButtonClicked"/>
    <LeftSideBarConfig :refParcel="refParcel" :user="user" v-show="!leftSideBarMap"  @close-config-side-bar="menuButtonClicked"></LeftSideBarConfig>
    <RightSideBar v-show="show_right_side_bar" v-bind:measures_to_show="measures_to_show" @close-side-bar="closeRightSideBar"/> 
    <div class="timeArrows"> <TimeArrows v-bind:current_date="this.current_date" v-bind:day="dailyMeasuresDisplay" @new-date="updateDate"/> </div>
    <div v-if="isDataLoading">
      <LoadingSpinner></LoadingSpinner>
    </div>
    <FormWindow  @new-measure-added="newMeasureAdded"  v-if="isFormWindowVisible" @close="closeWindowModal" @close-window-form="closeFormWindow" v-bind:selectedParcel="refParcel"> </FormWindow>
    <Popup :showPopup="showPopup" @close="closePopup"></Popup>
  </div>
</template>

<script>
// @: alias to '/src'
import LeftSideBar from '@/components/mapComponents/LeftSidebar.vue'
import Map from '@/components/mapComponents/Map.vue'
import RightSideBar from '@/components/mapComponents/RightSideBar.vue'
import "leaflet/dist/leaflet.css";
import * as db_interaction from '@/services/db_interaction'
import Marker from '@/models/Marker';
import Tabs from '@/components/mapComponents/Tabs.vue'
import TimeArrows from '@/components/mapComponents/TimeArrows.vue';
import * as date_functions from '@/services/date_functions'
import LoadingSpinner from "@/components/animatedComponents/LoadingSpinner.vue"
import * as backend_services from "@/services/backend_services";
import LeftSideBarConfig from '@/components/configComponents/leftSideBarConfig.vue';
import FormWindow from '@/components/configComponents/FormWindow.vue';
import Popup from '@/components/mapComponents/Popup.vue';

export default {
  name: 'MapView',
  data () {
    return {
      user_parcel_ids: [],
      phfb_measures: [],//db_interaction.getPHFBMeasures(),map
      ic_apex_measures: [],//db_interaction.getICAPEXMeasures(),
      show_right_side_bar: false,// used to show or hide right side bar 
      right_side_bar_cross_closing_click: 1,// variable used to propagate right_saide_bar closing event to child map component (the event is represented by the change of the variable value, toggling between -1 and 1)
      measures_to_show: [],// on rightSideBar (can be of any type)
      display_phfb: 'block',// to display or hide phfb layer on map
      display_ic_apex: 'none',// to display or hide ic-apex layer on map
      time_arrows_left:'24%',
      dailyMeasuresDisplay: false,
      current_date: new Date('01 Sep 2023'),// 13 June 2011
      isDataLoading: false,// to show or hide spinner
      leftSideBarMap: true, // to toggle between leftSideBarMap and leftSideBarConfig
      // spiderCoeffs: null,
      token: null,
      user: 'technician',
      refParcel: '',
      isFormWindowVisible: false,
      existingSpiderModel: null,
      showPopup: false,
    }
  },
  components: {
    LeftSideBar,
    RightSideBar,
    Map,
    Tabs,
    TimeArrows,
    LoadingSpinner,
    LeftSideBarConfig,
    FormWindow,
    Popup
  },
  computed: {
    // computed property containing the list of phfb markers calculated from the list of phfb measures
    phfb_markers(){
        let phfb_markers = [];
        for (let i = 0; i < this.phfb_measures.length; i++){
          phfb_markers.push(new Marker(this.phfb_measures[i].id, this.phfb_measures[i].latitude, this.phfb_measures[i].longitude, this.phfb_measures[i].measureType, this.phfb_measures[i].constraintClass));
        }
        return phfb_markers;
    },
    // computed propertry containing the list of ic-apex markers calculated from the list of ic-apex measures
    ic_apex_markers(){
        let ic_apex_markers = [];
        for (let i = 0; i < this.ic_apex_measures.length; i++){
          ic_apex_markers.push(new Marker(this.ic_apex_measures[i].id, this.ic_apex_measures[i].latitude, this.ic_apex_measures[i].longitude, this.ic_apex_measures[i].measureType, this.ic_apex_measures[i].constraintClass));
        }
        return ic_apex_markers;
    },
  },
  methods: {
    // callback function to react to marker clicked
    markerClicked(marker_type, marker_id, e) {
      // if shift key was not pressed -> reset measures to show (in rightSideBar) array
      if (!e.originalEvent.ctrlKey) {
        // reset array
        while (this.measures_to_show.length > 0) {
          this.measures_to_show.pop();
        }
      }
      // add corresponding measure to measures to show
      switch (marker_type){
        case 'phfb':
          this.measures_to_show.push(this.phfb_measures.find(measure => measure.id === marker_id));
        break;

        case 'ic-apex':
          this.measures_to_show.push(this.ic_apex_measures.find(measure => measure.id === marker_id));
        break;
      }
      // display right side bar
      this.show_right_side_bar = true;
    },

    // checkboxes clicked (checked/unchecked) -> update infos to show on map
    checkboxLayerClicked(e) {
      // check if checkbox checked or unchecked
      if (e.target.checked) {
        // check layer to show (checkbox id)
        switch(e.target.id) {
          case 'phfb':
            // display layer to show
            this.display_phfb = 'block';
          break;
          case 'ic-apex':
            // display layer to show
            this.display_ic_apex = 'block';
          break;
        }
      } else {
        // check layer to hide (checkbox id)
        switch(e.target.id) {
          case 'phfb':
            // hide layer 
            this.display_phfb = 'none';
          break;
          case 'ic-apex':
            // hide layer 
            this.display_ic_apex = 'none';
          break;
        }
      }
    },
    // toggle between daily and weekly measures display
    toggleTabButton(){
      this.dailyMeasuresDisplay = !this.dailyMeasuresDisplay;
      // update measures
      this.updateMeasures();
    },

    // callback function to react to rightSideBar's cross (x) clicked
    closeRightSideBar(e){
      // trigger right_side_bar closing event for Map child component
      this.right_side_bar_cross_closing_click = this.right_side_bar_cross_closing_click * (-1);
      // unshow right side bar
      this.show_right_side_bar = false;
      // reset array of measures to show 
      this.removeAllMeasuresToShow();
    },

    // callback function to react to map clicked on empty space (i.e not on a marker)
    mapClicked(){
      // unshow right side bar
      this.show_right_side_bar = false;
      // reset array of measures to show in right side bar
      this.removeAllMeasuresToShow();
    },

    // reset array all measures to show in rightSideBar
    removeAllMeasuresToShow() {
      while (this.measures_to_show.length > 0) {
          this.measures_to_show.pop();
      }
    },

    // update the current date used for visualisation
    updateDate(newDate) {
      // update date
      this.current_date = newDate;
      // update measures
      this.updateMeasures();
    },
    // get parcel IDs of user
    async getUserParcelIDs() {
      this.user_parcel_ids = await db_interaction.getUserParcelIDs();
    },

    // update ic_apex_measures from DB according to currentDate and display mode (estimation or real-data)
    // estimation -> measures of the day ; real-data -> measures of the week
    async updateICApexMeasures(pStartDate, pEndDate) {
        // get measures between dates range
        this.ic_apex_measures = await db_interaction.getICAPEXMeasures(pStartDate, pEndDate, this.user_parcel_ids);
        console.log('map pStartDate: ', pStartDate);
        console.log('map pEndDate: ', pEndDate);
    },
    // update phfb_measures from DB according to provided startDate and endDate 
    async updatePHFBMeasures(pStartDate, pEndDate) {
        // get measures between dates range
        this.phfb_measures = await db_interaction.getPHFBMeasures(pStartDate, pEndDate);
        // console.log('this.phfb_measures: ', this.phfb_measures);
    },
    async updateMeasures(){
      this.isDataLoading = true;
      // get parcel IDs of user
      // this.getUserParcelIDs();

      // todo: use parcel IDs in the measures requests
      console.log('this.current_date: ', this.current_date);
      // compute date range
      const dateRange = date_functions.computeDateRange(this.current_date);
      
      // request ic-apex DB within date range
      // this.updateICApexMeasures(dateRange[0], dateRange[1]);
      // request phfb DB within date range
      console.log('dateRange: ', dateRange);
      this.updatePHFBMeasures(dateRange[0], dateRange[1]);
      // Pause execution to avoid immediate clicking on time arrow button (immediate clicking sometimes causes loading errors)
      await new Promise(resolve => setTimeout(resolve, 1250));
      this.isDataLoading = false;
      console.log('measures phfb: ', this.phfb_measures);
      // console.log('phfb markers: ', this.phfb_markers);
    },
    async updateDateToLatestPHFBMeasureDate() {
      this.isDataLoading = true;
      const date = await db_interaction.getLatestPHFBMeasureDate();
      this.current_date = new Date(date_functions.changeStringDateFormat(date));
      console.log('latest date conv: ', date, 'current date: ', this.current_date);
      this.isDataLoading = false;
    },
    // async getParcelNamesPHFB() {
    //   await backend_services.getParcelNamesPHFB();
    // },
    menuButtonClicked() {
      this.leftSideBarMap = ! this.leftSideBarMap;
    },
    // gets the memorized parameters in the local storage if any
    getMemoParameters() {
      this.token = localStorage.getItem('token');
      if (this.token == null) {
        this.$route.push('/');
      }
      this.showPopup = localStorage.getItem('showPopup') == null ? false : localStorage.getItem('showPopup') == 'true' ? true : false;
      console.log('show pop up: ', this.showPopup);
      this.existingSpiderModel = (localStorage.getItem('existingSpiderModel') == null) ? false : ((localStorage.getItem('existingSpiderModel') == 'true') ? true : false);
      this.refParcel = localStorage.getItem('refParcel')
      this.current_date = (localStorage.getItem('current_date') == null ) ? this.current_date : new Date(localStorage.getItem('current_date'));
      // this.display_phfb = localStorage.getItem('display_phfb') == null ? 'none' : localStorage.getItem('display_phfb');
      this.display_ic_apex = localStorage.getItem('display_ic_apex') == null ? 'none' : localStorage.getItem('display_ic_apex');
      if (localStorage.getItem('dailyMeasuresDisplay') == null){
        this.dailyMeasuresDisplay = false;
      } else {
        if (localStorage.getItem('dailyMeasuresDisplay') === 'true') {
          this.dailyMeasuresDisplay = true;
        } else {
          this.dailyMeasuresDisplay = false;
        }
      }
    },
    newRefParcelMeasure(){
      this.isFormWindowVisible = true;
    },
    async newRefParcelBilanHydrique() {
      const token = await backend_services.getTokenAppEau('supagro','JpheuiLk98');
      const bilan_hydrique = await backend_services.getPHFBFromAppEau(token);
      console.log('token: ', token);
      console.log('bilan_hydrique: ', bilan_hydrique);
      
    },
    closeFormWindow(){
      this.isFormWindowVisible = false;
    },
    newMeasureAdded(newDate){
      this.current_date = new Date(newDate);
      this.updateMeasures();
    },
    closePopup() {
      this.showPopup = false;
    }
  },
  
  created () {
    // get latest PHFB measure's date
    // this.updateDateToLatestPHFBMeasureDate();

    // update measures
    this.updateMeasures();
  },
  beforeRouteLeave() {
    // clear history
    localStorage.clear();
    // memorise variables value
    localStorage.setItem('current_date', this.current_date);
    // localStorage.setItem('display_phfb', this.display_phfb);
    localStorage.setItem('display_ic_apex', this.display_ic_apex);
    localStorage.setItem('dailyMeasuresDisplay', this.dailyMeasuresDisplay);
    localStorage.setItem('token', this.token);
    localStorage.setItem('refParcel', this.refParcel);
    localStorage.setItem('existingSpiderModel', this.existingSpiderModel);
  },

  mounted(){
    // get memorised paramaters if any
    this.getMemoParameters();
    console.log('test local storage : ', localStorage.getItem('existingSpiderModel'));
  }
}
</script>


<style scoped>
div{
  position:fixed;
}

.timeArrows{
    position: fixed;
    top: 7.4%;
    width: 10%;
    left: v-bind('time_arrows_left');
    background-color: rgb(239, 243, 248);
}
</style>@/components/mapComponents/Popup.vue