export const CONSTRAINT_CLASSES = [
    {"date":"01/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"02/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"03/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"04/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"05/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"06/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"07/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"08/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"09/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"10/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"11/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"12/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"13/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"14/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"15/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"16/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"17/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"18/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"19/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"20/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"21/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"22/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"23/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"24/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"25/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"26/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"27/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"28/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"29/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"30/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"31/01/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"01/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"02/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"03/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"04/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"05/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"06/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"07/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"08/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"09/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"10/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"11/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"12/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"13/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"14/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"15/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"16/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"17/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"18/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"19/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"20/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"21/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"22/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"23/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"24/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"25/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"26/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"27/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"28/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"29/02/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"01/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"02/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"03/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"04/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"05/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"06/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"07/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"08/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"09/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"10/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"11/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"12/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"13/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"14/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"15/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"16/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"17/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"18/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"19/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"20/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"21/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"22/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"23/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"24/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"25/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"26/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"27/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"28/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"29/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"30/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"31/03/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"01/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"02/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"03/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"04/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"05/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"06/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"07/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"08/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"09/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"10/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"11/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"12/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"13/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"14/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"15/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"16/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"17/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"18/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"19/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"20/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"21/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"22/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"23/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"24/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"25/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,23080147","E":"-1,5"},
    {"date":"26/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,230896123","E":"-1,5"},
    {"date":"27/04/2000","A":"0","B":"0","C":"-0,123926141","D":"-0,231178594","E":"-1,5"},
    {"date":"28/04/2000","A":"0","B":"0","C":"-0,12399404","D":"-0,231646469","E":"-1,5"},
    {"date":"29/04/2000","A":"0","B":"0","C":"-0,124244793","D":"-0,232297335","E":"-1,5"},
    {"date":"30/04/2000","A":"0","B":"0","C":"-0,124675876","D":"-0,233128779","E":"-1,5"},
    {"date":"01/05/2000","A":"0","B":"0","C":"-0,125284767","D":"-0,234138387","E":"-1,5"},
    {"date":"02/05/2000","A":"0","B":"0","C":"-0,126068943","D":"-0,235323748","E":"-1,5"},
    {"date":"03/05/2000","A":"0","B":"0","C":"-0,127025879","D":"-0,236682447","E":"-1,5"},
    {"date":"04/05/2000","A":"0","B":"0","C":"-0,128153054","D":"-0,238212072","E":"-1,5"},
    {"date":"05/05/2000","A":"0","B":"0","C":"-0,129447943","D":"-0,239910209","E":"-1,5"},
    {"date":"06/05/2000","A":"0","B":"0","C":"-0,130908024","D":"-0,241774445","E":"-1,5"},
    {"date":"07/05/2000","A":"0","B":"0","C":"-0,132530773","D":"-0,243802367","E":"-1,5"},
    {"date":"08/05/2000","A":"0","B":"0","C":"-0,134313667","D":"-0,245991562","E":"-1,5"},
    {"date":"09/05/2000","A":"0","B":"0","C":"-0,136254183","D":"-0,248339617","E":"-1,5"},
    {"date":"10/05/2000","A":"0","B":"0","C":"-0,138349797","D":"-0,250844118","E":"-1,5"},
    {"date":"11/05/2000","A":"0","B":"0","C":"-0,140597987","D":"-0,253502653","E":"-1,5"},
    {"date":"12/05/2000","A":"0","B":"0","C":"-0,142996229","D":"-0,256312808","E":"-1,5"},
    {"date":"13/05/2000","A":"0","B":"0","C":"-0,145542001","D":"-0,25927217","E":"-1,5"},
    {"date":"14/05/2000","A":"0","B":"0","C":"-0,148232778","D":"-0,262378327","E":"-1,5"},
    {"date":"15/05/2000","A":"0","B":"0","C":"-0,151066037","D":"-0,265628864","E":"-1,5"},
    {"date":"16/05/2000","A":"0","B":"0","C":"-0,154039256","D":"-0,269021369","E":"-1,5"},
    {"date":"17/05/2000","A":"0","B":"0","C":"-0,157149911","D":"-0,272553429","E":"-1,5"},
    {"date":"18/05/2000","A":"0","B":"0","C":"-0,160395479","D":"-0,27622263","E":"-1,5"},
    {"date":"19/05/2000","A":"0","B":"0","C":"-0,163773437","D":"-0,28002656","E":"-1,5"},
    {"date":"20/05/2000","A":"0","B":"0","C":"-0,167281262","D":"-0,283962805","E":"-1,5"},
    {"date":"21/05/2000","A":"0","B":"0","C":"-0,170916429","D":"-0,288028952","E":"-1,5"},
    {"date":"22/05/2000","A":"0","B":"0","C":"-0,174676417","D":"-0,292222588","E":"-1,5"},
    {"date":"23/05/2000","A":"0","B":"0","C":"-0,178558702","D":"-0,2965413","E":"-1,5"},
    {"date":"24/05/2000","A":"0","B":"-0,001014911","C":"-0,18256076","D":"-0,300982675","E":"-1,5"},
    {"date":"25/05/2000","A":"0","B":"-0,005449659","C":"-0,186680069","D":"-0,305544299","E":"-1,5"},
    {"date":"26/05/2000","A":"0","B":"-0,009993416","C":"-0,190914105","D":"-0,310223759","E":"-1,5"},
    {"date":"27/05/2000","A":"0","B":"-0,01464352","C":"-0,195260345","D":"-0,315018643","E":"-1,5"},
    {"date":"28/05/2000","A":"0","B":"-0,01939731","C":"-0,199716265","D":"-0,319926537","E":"-1,5"},
    {"date":"29/05/2000","A":"0","B":"-0,024252123","C":"-0,204279344","D":"-0,324945028","E":"-1,5"},
    {"date":"30/05/2000","A":"0","B":"-0,029205297","C":"-0,208947057","D":"-0,330071703","E":"-1,5"},
    {"date":"31/05/2000","A":"0","B":"-0,034254171","C":"-0,213716881","D":"-0,335304148","E":"-1,5"},
    {"date":"01/06/2000","A":"0","B":"-0,03939608","C":"-0,218586292","D":"-0,340639952","E":"-1,5"},
    {"date":"02/06/2000","A":"0","B":"-0,044628365","C":"-0,223552769","D":"-0,346076699","E":"-1,5"},
    {"date":"03/06/2000","A":"-0,001990143","B":"-0,049948362","C":"-0,228613787","D":"-0,351611978","E":"-1,5"},
    {"date":"04/06/2000","A":"-0,00559653","B":"-0,055353409","C":"-0,233766824","D":"-0,357243375","E":"-1,5"},
    {"date":"05/06/2000","A":"-0,009264377","B":"-0,060840844","C":"-0,239009355","D":"-0,362968477","E":"-1,5"},
    {"date":"06/06/2000","A":"-0,012991458","B":"-0,066408005","C":"-0,244338859","D":"-0,368784871","E":"-1,5"},
    {"date":"07/06/2000","A":"-0,016775545","B":"-0,07205223","C":"-0,249752811","D":"-0,374690144","E":"-1,5"},
    {"date":"08/06/2000","A":"-0,020614411","B":"-0,077770857","C":"-0,255248689","D":"-0,380681883","E":"-1,5"},
    {"date":"09/06/2000","A":"-0,024505829","B":"-0,083561223","C":"-0,260823969","D":"-0,386757674","E":"-1,5"},
    {"date":"10/06/2000","A":"-0,028447572","B":"-0,089420666","C":"-0,266476128","D":"-0,392915104","E":"-1,5"},
    {"date":"11/06/2000","A":"-0,032437413","B":"-0,095346525","C":"-0,272202643","D":"-0,39915176","E":"-1,5"},
    {"date":"12/06/2000","A":"-0,036473126","B":"-0,101336137","C":"-0,27800099","D":"-0,40546523","E":"-1,5"},
    {"date":"13/06/2000","A":"-0,040552482","B":"-0,107386839","C":"-0,283868647","D":"-0,4118531","E":"-1,5"},
    {"date":"14/06/2000","A":"-0,044673255","B":"-0,11349597","C":"-0,28980309","D":"-0,418312956","E":"-1,5"},
    {"date":"15/06/2000","A":"-0,048833219","B":"-0,119660868","C":"-0,295801796","D":"-0,424842386","E":"-1,5"},
    {"date":"16/06/2000","A":"-0,053030145","B":"-0,125878869","C":"-0,301862242","D":"-0,431438976","E":"-1,5"},
    {"date":"17/06/2000","A":"-0,057261807","B":"-0,132147314","C":"-0,307981904","D":"-0,438100314","E":"-1,5"},
    {"date":"18/06/2000","A":"-0,061525977","B":"-0,138463538","C":"-0,31415826","D":"-0,444823987","E":"-1,5"},
    {"date":"19/06/2000","A":"-0,06582043","B":"-0,14482488","C":"-0,320388785","D":"-0,45160758","E":"-1,5"},
    {"date":"20/06/2000","A":"-0,070142937","B":"-0,151228678","C":"-0,326670958","D":"-0,458448681","E":"-1,5"},
    {"date":"21/06/2000","A":"-0,074491272","B":"-0,157672269","C":"-0,333002254","D":"-0,465344877","E":"-1,5"},
    {"date":"22/06/2000","A":"-0,078863207","B":"-0,164152992","C":"-0,339380151","D":"-0,472293755","E":"-1,5"},
    {"date":"23/06/2000","A":"-0,083256516","B":"-0,170668184","C":"-0,345802125","D":"-0,479292902","E":"-1,5"},
    {"date":"24/06/2000","A":"-0,087668972","B":"-0,177215183","C":"-0,352265654","D":"-0,486339903","E":"-1,5"},
    {"date":"25/06/2000","A":"-0,092098347","B":"-0,183791327","C":"-0,358768213","D":"-0,493432348","E":"-1,5"},
    {"date":"26/06/2000","A":"-0,096542415","B":"-0,190393953","C":"-0,365307279","D":"-0,500567821","E":"-1,5"},
    {"date":"27/06/2000","A":"-0,100998948","B":"-0,197020401","C":"-0,37188033","D":"-0,50774391","E":"-1,5"},
    {"date":"28/06/2000","A":"-0,105465719","B":"-0,203668006","C":"-0,378484843","D":"-0,514958202","E":"-1,5"},
    {"date":"29/06/2000","A":"-0,109940502","B":"-0,210334108","C":"-0,385118293","D":"-0,522208284","E":"-1,5"},
    {"date":"30/06/2000","A":"-0,11442107","B":"-0,217016045","C":"-0,391778158","D":"-0,529491743","E":"-1,5"},
    {"date":"01/07/2000","A":"-0,118905194","B":"-0,223711153","C":"-0,398461915","D":"-0,536806165","E":"-1,5"},
    {"date":"02/07/2000","A":"-0,123390649","B":"-0,230416771","C":"-0,40516704","D":"-0,544149137","E":"-1,5"},
    {"date":"03/07/2000","A":"-0,127875207","B":"-0,237130236","C":"-0,41189101","D":"-0,551518247","E":"-1,5"},
    {"date":"04/07/2000","A":"-0,132356641","B":"-0,243848887","C":"-0,418631302","D":"-0,55891108","E":"-1,5"},
    {"date":"05/07/2000","A":"-0,136832725","B":"-0,250570062","C":"-0,425385393","D":"-0,566325225","E":"-1,5"},
    {"date":"06/07/2000","A":"-0,14130123","B":"-0,257291097","C":"-0,432150759","D":"-0,573758267","E":"-1,5"},
    {"date":"07/07/2000","A":"-0,145759931","B":"-0,264009332","C":"-0,438924878","D":"-0,581207794","E":"-1,5"},
    {"date":"08/07/2000","A":"-0,150206599","B":"-0,270722103","C":"-0,445705225","D":"-0,588671393","E":"-1,5"},
    {"date":"09/07/2000","A":"-0,154639009","B":"-0,277426749","C":"-0,452489279","D":"-0,59614665","E":"-1,5"},
    {"date":"10/07/2000","A":"-0,159054932","B":"-0,284120608","C":"-0,459274515","D":"-0,603631152","E":"-1,5"},
    {"date":"11/07/2000","A":"-0,163452143","B":"-0,290801017","C":"-0,466058411","D":"-0,611122486","E":"-1,5"},
    {"date":"12/07/2000","A":"-0,167828413","B":"-0,297465314","C":"-0,472838443","D":"-0,618618239","E":"-1,5"},
    {"date":"13/07/2000","A":"-0,172181516","B":"-0,304110838","C":"-0,479612087","D":"-0,626115998","E":"-1,5"},
    {"date":"14/07/2000","A":"-0,176509225","B":"-0,310734925","C":"-0,486376822","D":"-0,633613349","E":"-1,5"},
    {"date":"15/07/2000","A":"-0,180809313","B":"-0,317334914","C":"-0,493130123","D":"-0,641107881","E":"-1,5"},
    {"date":"16/07/2000","A":"-0,185079552","B":"-0,323908142","C":"-0,499869468","D":"-0,648597178","E":"-1,5"},
    {"date":"17/07/2000","A":"-0,189317716","B":"-0,330451948","C":"-0,506592333","D":"-0,656078829","E":"-1,5"},
    {"date":"18/07/2000","A":"-0,193521578","B":"-0,336963669","C":"-0,513296194","D":"-0,66355042","E":"-1,5"},
    {"date":"19/07/2000","A":"-0,197688911","B":"-0,343440643","C":"-0,51997853","D":"-0,671009538","E":"-1,5"},
    {"date":"20/07/2000","A":"-0,201817487","B":"-0,349880209","C":"-0,526636816","D":"-0,67845377","E":"-1,5"},
    {"date":"21/07/2000","A":"-0,205905079","B":"-0,356279703","C":"-0,533268529","D":"-0,685880702","E":"-1,5"},
    {"date":"22/07/2000","A":"-0,209949461","B":"-0,362636463","C":"-0,539871146","D":"-0,693287923","E":"-1,5"},
    {"date":"23/07/2000","A":"-0,213948406","B":"-0,368947828","C":"-0,546442145","D":"-0,700673017","E":"-1,5"},
    {"date":"24/07/2000","A":"-0,217899686","B":"-0,375211136","C":"-0,552979","D":"-0,708033573","E":"-1,5"},
    {"date":"25/07/2000","A":"-0,221801074","B":"-0,381423723","C":"-0,559479191","D":"-0,715367177","E":"-1,5"},
    {"date":"26/07/2000","A":"-0,225650343","B":"-0,387582929","C":"-0,565940192","D":"-0,722671416","E":"-1,5"},
    {"date":"27/07/2000","A":"-0,229445267","B":"-0,39368609","C":"-0,572359482","D":"-0,729943877","E":"-1,5"},
    {"date":"28/07/2000","A":"-0,233183619","B":"-0,399730545","C":"-0,578734536","D":"-0,737182147","E":"-1,5"},
    {"date":"29/07/2000","A":"-0,23686317","B":"-0,405713631","C":"-0,585062833","D":"-0,744383812","E":"-1,5"},
    {"date":"30/07/2000","A":"-0,240481695","B":"-0,411632687","C":"-0,591341847","D":"-0,75154646","E":"-1,5"},
    {"date":"31/07/2000","A":"-0,244036965","B":"-0,41748505","C":"-0,597569057","D":"-0,758667677","E":"-1,5"},
    {"date":"01/08/2000","A":"-0,247526755","B":"-0,423268058","C":"-0,603741938","D":"-0,765745051","E":"-1,5"},
    {"date":"02/08/2000","A":"-0,250948837","B":"-0,428979049","C":"-0,609857968","D":"-0,772776167","E":"-1,5"},
    {"date":"03/08/2000","A":"-0,254300984","B":"-0,43461536","C":"-0,615914624","D":"-0,779758614","E":"-1,5"},
    {"date":"04/08/2000","A":"-0,257580969","B":"-0,440174331","C":"-0,621909382","D":"-0,786689977","E":"-1,5"},
    {"date":"05/08/2000","A":"-0,260786565","B":"-0,445653297","C":"-0,62783972","D":"-0,793567844","E":"-1,5"},
    {"date":"06/08/2000","A":"-0,263915545","B":"-0,451049598","C":"-0,633703113","D":"-0,800389801","E":"-1,5"},
    {"date":"07/08/2000","A":"-0,266965682","B":"-0,456360571","C":"-0,639497039","D":"-0,807153436","E":"-1,5"},
    {"date":"08/08/2000","A":"-0,269934749","B":"-0,461583553","C":"-0,645218974","D":"-0,813856335","E":"-1,5"},
    {"date":"09/08/2000","A":"-0,272820518","B":"-0,466715884","C":"-0,650866396","D":"-0,820496085","E":"-1,5"},
    {"date":"10/08/2000","A":"-0,275620763","B":"-0,4717549","C":"-0,65643678","D":"-0,827070273","E":"-1,5"},
    {"date":"11/08/2000","A":"-0,278333257","B":"-0,476697939","C":"-0,661927605","D":"-0,833576486","E":"-1,5"},
    {"date":"12/08/2000","A":"-0,280955773","B":"-0,48154234","C":"-0,667336346","D":"-0,840012311","E":"-1,5"},
    {"date":"13/08/2000","A":"-0,283486083","B":"-0,48628544","C":"-0,67266048","D":"-0,846375334","E":"-1,5"},
    {"date":"14/08/2000","A":"-0,285921961","B":"-0,490924577","C":"-0,677897485","D":"-0,852663143","E":"-1,5"},
    {"date":"15/08/2000","A":"-0,28826118","B":"-0,495457088","C":"-0,683044837","D":"-0,858873324","E":"-1,5"},
    {"date":"16/08/2000","A":"-0,290501512","B":"-0,499880313","C":"-0,688100012","D":"-0,865003464","E":"-1,5"},
    {"date":"17/08/2000","A":"-0,29264073","B":"-0,504191587","C":"-0,693060488","D":"-0,87105115","E":"-1,5"},
    {"date":"18/08/2000","A":"-0,294676608","B":"-0,50838825","C":"-0,697923742","D":"-0,877013969","E":"-1,5"},
    {"date":"19/08/2000","A":"-0,296606919","B":"-0,51246764","C":"-0,702687249","D":"-0,882889507","E":"-1,5"},
    {"date":"20/08/2000","A":"-0,298429435","B":"-0,516427093","C":"-0,707348487","D":"-0,888675353","E":"-1,5"},
    {"date":"21/08/2000","A":"-0,300141929","B":"-0,520263948","C":"-0,711904933","D":"-0,894369091","E":"-1,5"},
    {"date":"22/08/2000","A":"-0,301742174","B":"-0,523975543","C":"-0,716354064","D":"-0,89996831","E":"-1,5"},
    {"date":"23/08/2000","A":"-0,303227944","B":"-0,527559215","C":"-0,720693356","D":"-0,905470597","E":"-1,5"},
    {"date":"24/08/2000","A":"-0,304597011","B":"-0,531012302","C":"-0,724920286","D":"-0,910873537","E":"-1,5"},
    {"date":"25/08/2000","A":"-0,305847149","B":"-0,534332143","C":"-0,729032331","D":"-0,916174718","E":"-1,5"},
    {"date":"26/08/2000","A":"-0,306976129","B":"-0,537516075","C":"-0,733026967","D":"-0,921371727","E":"-1,5"},
    {"date":"27/08/2000","A":"-0,307981725","B":"-0,540561436","C":"-0,736901672","D":"-0,926462151","E":"-1,5"},
    {"date":"28/08/2000","A":"-0,308861711","B":"-0,543465563","C":"-0,740653922","D":"-0,931443576","E":"-1,5"},
    {"date":"29/08/2000","A":"-0,309613859","B":"-0,546225795","C":"-0,744281194","D":"-0,936313589","E":"-1,5"},
    {"date":"30/08/2000","A":"-0,310235941","B":"-0,54883947","C":"-0,747780964","D":"-0,941069778","E":"-1,5"},
    {"date":"31/08/2000","A":"-0,310725732","B":"-0,551303924","C":"-0,751150711","D":"-0,945709729","E":"-1,5"},
    {"date":"01/09/2000","A":"-0,311081004","B":"-0,553616497","C":"-0,75438791","D":"-0,950231028","E":"-1,5"},
    {"date":"02/09/2000","A":"-0,311299529","B":"-0,555774526","C":"-0,757490037","D":"-0,954631264","E":"-1,5"},
    {"date":"03/09/2000","A":"-0,311379082","B":"-0,557775348","C":"-0,760454571","D":"-0,958908022","E":"-1,5"},
    {"date":"04/09/2000","A":"-0,311379082","B":"-0,559616302","C":"-0,763278988","D":"-0,96305889","E":"-1,5"},
    {"date":"05/09/2000","A":"-0,311379082","B":"-0,561294725","C":"-0,765960764","D":"-0,967081454","E":"-1,5"},
    {"date":"06/09/2000","A":"-0,311379082","B":"-0,562807956","C":"-0,768497376","D":"-0,970973301","E":"-1,5"},
    {"date":"07/09/2000","A":"-0,311379082","B":"-0,564153332","C":"-0,770886301","D":"-0,974732019","E":"-1,5"},
    {"date":"08/09/2000","A":"-0,311379082","B":"-0,565328191","C":"-0,773125017","D":"-0,978355194","E":"-1,5"},
    {"date":"09/09/2000","A":"-0,311379082","B":"-0,566329871","C":"-0,775210999","D":"-0,981840412","E":"-1,5"},
    {"date":"10/09/2000","A":"-0,311379082","B":"-0,567155709","C":"-0,777141724","D":"-0,985185261","E":"-1,5"},
    {"date":"11/09/2000","A":"-0,311379082","B":"-0,567803044","C":"-0,77891467","D":"-0,988387328","E":"-1,5"},
    {"date":"12/09/2000","A":"-0,311379082","B":"-0,568269213","C":"-0,780527312","D":"-0,991444199","E":"-1,5"},
    {"date":"13/09/2000","A":"-0,311379082","B":"-0,568551554","C":"-0,781977128","D":"-0,994353461","E":"-1,5"},
    {"date":"14/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"15/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"16/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"17/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"18/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"19/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"20/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"21/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"22/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"23/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"24/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"25/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"26/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"27/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"28/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"29/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"30/09/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"01/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"02/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"03/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"04/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"05/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"06/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"07/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"08/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"09/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"10/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"11/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"12/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"13/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"14/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"15/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"16/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"17/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"18/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"19/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"20/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"21/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"22/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"23/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"24/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"25/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"26/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"27/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"28/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"29/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"30/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"31/10/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"01/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"02/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"03/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"04/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"05/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"06/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"07/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"08/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"09/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"10/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"11/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"12/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"13/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"14/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"15/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"16/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"17/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"18/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"19/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"20/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"21/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"22/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"23/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"24/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"25/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"26/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"27/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"28/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"29/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"30/11/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"01/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"02/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"03/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"04/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"05/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"06/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"07/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"08/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"09/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"10/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"11/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"12/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"13/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"14/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"15/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"16/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"17/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"18/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"19/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"20/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"21/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"22/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"23/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"24/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"25/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"26/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"27/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"28/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"29/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"30/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"},
    {"date":"31/12/2000","A":"-0,311379082","B":"-0,568647405","C":"-0,783261595","D":"-0,997112702","E":"-1,5"}
]