<template>
    <div class="container" >
        <span class="close" @click="close">&times;</span>
        <h2 class="mainTitle">Ajout d'un nouveau territoire</h2>
        <div >
            <div class="territoryNameContainer">
                <p class="subTitle"><b>Nom du territoire</b></p>
                <input v-model="this.newTerritory" type="text"/>
            </div>
            
            <div class="refParcelsContainer">
                <p class="subTitle"><b>Parcelles de référence</b></p>
                <p class="subTitle"><a href="refFile">Télécharger le fichier modèle</a></p>
                <input type="file" @change="referencesFileChange">
            </div>

            <div class="satelliteParcelsContainer">
                <p class="subTitle"><b>Parcelles satellites</b></p>
                <p class="subTitle"><a href="satFile">Télécharger le fichier modèle</a></p>
                <input type="file" @change="satellitesFileChange">
            </div>

            <div class="button">
                <button :disabled="this.referencesFile === null || this.satellitesFile === null" type="submit" @click="clickButton">Valider</button>
            </div>
        </div>

    </div>
    <div v-if="isDataLoading">
      <LoadingSpinner></LoadingSpinner>
    </div>


</template>


<script>
import * as db_interaction from '@/services/db_interaction'
import * as backend_services from '@/services/backend_services'
import LoadingSpinner from "@/components/animatedComponents/LoadingSpinner.vue"

export default{
    
    data () {
        return {
            isDataLoading: false,
            validSeasons: [],
            validParcels: [],
            candidateRefParcels: [],
            newTerritory: null,
            refParcel: null,
            existingSpiderModel: false,
            chosenSeasons: [],
            coeffs: null,
            message:'',
            fileInput: false,
            referencesFile: null,
            satellitesFile: null
        }
    },
    props: {
    },
    components: {
        LoadingSpinner
    },
    methods:{
        async referencesFileChange(e){
            this.referencesFile = e.target.files[0];
        },

        async satellitesFileChange(e){
            this.satellitesFile = e.target.files[0];
        },

        async clickButton(){
            this.isDataLoading = true;
            // import measures and process spider model
            await backend_services.importInitialMeasures(this.referencesFile, this.satellitesFile);
            this.isDataLoading = false;
            // this.$emit('new-territory-set-up', this.newTerritory);
            localStorage.setItem('territory', this.newTerritory);
            localStorage.setItem('existingSpiderModel', 'true');
            // this.$router.push({name: 'mapView', params: { paramTest: this.newTerritory}});
            this.$router.push({ path: '/mapView'});
        },
        getMemoParams() {
            console.log('localStorage.getItem', localStorage.getItem('existingSpiderModel'));
            this.existingSpiderModel = (localStorage.getItem('existingSpiderModel') == null) ? false : localStorage.getItem('existingSpiderModel') == 'true' ? true : false;
            this.refParcel = localStorage.getItem('refParcel');
        },
        close(){
            this.$router.push('/mapView');
        }
    },
    created(){
        this.getMemoParams();
    },
}
</script>

<style scoped>
    div.container {
        position: fixed;
        left: 0vw;
        top:6vh;
        width: 100vw;
        height: 94vh;
        background-color: hsl(180, 30%, 89%);
        z-index: 0;
    }

    .territoryNameContainer{
        position: absolute;
        top: 10%;
        left: 45%;
    }

    .refParcelsContainer {
        position: absolute;
        top: 20%;
        left: 45%;
    }

    .satelliteParcelsContainer {
        position: absolute;
        top: 34%;
        left: 45%;
    }

    a{
        margin-left: 0;
        padding-left: 0;
        left: -2px;
    }


    
    .dropDownContainer{
        position: absolute;
        top: 25%;
        left: 5%;
        margin-left: 0;
        padding-left: 0;
    }
    .button{
        position: absolute;
        top: 50%;
        left: 50%;
    }
    button{
        height:30px;
        font-weight: bold;
    }
    button:hover:enabled{
        cursor: pointer;
    }
    li{
        list-style-type: none;
        margin: 0px 5px;
    }
    .newModel {
        margin-top: 20px;
        margin-left: 50px;
    }

    .existingSpiderModel{
        position: absolute;
        top: 40%;
        left: 40%;
    }

    /* styling for the grid */
    table {
      border-collapse: collapse;
      width: 100%;
      border: 2px solid #ddd; /* Add border to the table */
    }
    th, td {
      border: 1px solid #ddd; /* Add border to table cells */
      padding: 8px;
      text-align: left;
    }
    th {
      background-color: #f2f2f2;
    }
    h2{
        margin-bottom: 20px;
        margin-left: 10px;
    }
    .mainTitle{
        text-align: center;
        padding-bottom: 20px;
    }
    .subTitle{
        margin-left: 0;
        padding-left: 0;
        position: relative;
        left: 0px;
    }
    li{
        margin-left: 0;
        padding-left: 0;
    }
    .close {
        font-size: 45px;
        font-weight: 500;
        display: inline-block;
        position: absolute;
        left: 1%;
        top: 0%;
        color:rgb(107, 102, 102);
    }
    .close:hover{
        cursor: pointer;
        color:rgb(51, 106, 87);
        transform:scale(1.1);
    }


</style>