<template>
    <div class="overlay" v-if="showPopup">
      
      <div class="popup">
        <span class="close" @click="closePopup">&times;</span>
        <h2> Modèle Spider étalonné ! </h2>
        <p> Vous pouvez désormais estimer des valeurs de PHFB pour les parcelles satellites en ajoutant des mesures 
        pour la parcelle de référence </p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      showPopup: Boolean,
      title: String,
      message: String
    },
    methods: {
      closePopup() {
        this.$emit('close'); 
      }
    },
    mounted() {
      setTimeout(() => {
        this.$emit('close');
      }, 10000);
    }
  };
  </script>
  
  <style scoped>
  .overlay{
    position: fixed;
    top: 6vh;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1000;
  }
  
  .popup {
    background-color: rgb(222,235,247);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    width: 20%;
    position: absolute;
    left: 45%;
    top: 30%;
  }

  .close {
    font-size: 45px;
    font-weight: 500;
    display: inline-block;
    position: absolute;
    left: 2%;
    top: -3%;
    color:rgb(107, 102, 102);
  }
  .close:hover{
    cursor: pointer;
    color:rgb(51, 106, 87);
    transform:scale(1.1);
  }

  h2{
    text-align: center;
  }
  p{
    font-size: small;
    text-align: left;
  }
</style>