<template>
    <div class="home">
      <!-- <LeftSideBarConfig :showParcelsManagement="showParcelsManagement" :refParcel="this.refParcel" :user="user" v-show="!leftSideBarMap" @close-config-side-bar="closeConfigSideBar" @parcel-link-clicked="parcelLinkClicked" @spider-model-link-clicked="spiderModeLinkClicked"></LeftSideBarConfig> -->

      <SpiderModelComputing v-if="this.territorySetUp" :refParcel="this.refParcel" :existingSpiderModel="this.existingSpiderModel" @generated-spider-coeffs="generatedSpiderCoeffs" @new-model-calculated="newModelCalculated" ></SpiderModelComputing>

      <NewTerritory v-else> </NewTerritory>

      
    </div>
  </template>
  
  <script>
  // @: alias to '/src'
  import "leaflet/dist/leaflet.css";
  import LeftSideBarConfig from '@/components/configComponents/leftSideBarConfig.vue';
  import SpiderModelComputing from "@/components/configComponents/SpiderModelComputing.vue";
  import ParcelsList from "@/components/configComponents/ParcelsList.vue";
  import NewTerritory from "@/components/configComponents/NewTerritory.vue";

  export default {
    name: 'MapView',
    data () {
      return {
        coeffs: null,
        showParcelsManagement: null,
        user: 'technician',
        refParcel: '',
        existingSpiderModel: null,
        showPopup: false,
        territorySetUp: null,
      }
    },
    components: {
      LeftSideBarConfig,
      SpiderModelComputing,
      ParcelsList,
      NewTerritory
    },
    computed: {
    },
    methods: {
      newModelCalculated(e){
        this.refParcel = e;
        if (this.refParcel != null) {
          this.existingSpiderModel = true;
          this.showPopup = true;
          this.$router.push('/mapView');
        } else {
          this.existingSpiderModel = false;
        }
        
      },
      closeConfigSideBar() {
        this.$router.push('/mapView');
      },
      parcelLinkClicked(){
        this.showParcelsManagement = true;
        console.log('parcel');
      },
      spiderModeLinkClicked() {
        this.showParcelsManagement = false;
        console.log('spider');
      },
      getMemoParameters(){
      this.refParcel = localStorage.getItem('refParcel');
      this.existingSpiderModel = localStorage.getItem('existingSpiderModel') == null ? false : localStorage.getItem('existingSpiderModel') == 'true' ? true : false;
      console.log('existing config view entering: ', this.existingSpiderModel);
    }
    },
    created () {
      this.showParcelsManagement = this.$route.query.showParcelsManagement == 'true';
      this.territorySetUp = false;
      this.getMemoParameters();
    },
    beforeRouteLeave() {
      // save spider coeffs
      console.log('existing config view: ', this.existingSpiderModel);
      localStorage.setItem('refParcel', this.refParcel);
      localStorage.setItem('showPopup', this.showPopup);
      this.showPopup = false;
    },
  }
  </script>