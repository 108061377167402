<template>
    <div class="home">
      <LeftSideBarConfig :refParcel="this.refParcel" :user="user" v-show="!leftSideBarMap" @close-config-side-bar="closeConfigSideBar" @parcel-link-clicked="parcelLinkClicked" @spider-model-link-clicked="spiderModeLinkClicked"></LeftSideBarConfig>
      <ParcelsList :parcelToShow="parcelToShow" :user="this.user" v-if="showParcelsManagement"></ParcelsList>
    </div>
  </template>
  
  <script>
  // @: alias to '/src'
  import "leaflet/dist/leaflet.css";
  import LeftSideBarConfig from '@/components/configComponents/leftSideBarConfig.vue';
  import ParcelsList from "@/components/configComponents/ParcelsList.vue";

  export default {
    name: 'MapView',
    data () {
      return {
        showParcelsManagement: null,
        user: 'technician',
        parcelToShow: null
      }
    },
    components: {
      LeftSideBarConfig,
      ParcelsList
    },
    methods: {
      closeConfigSideBar() {
        this.$router.push('/mapView');
      },
      parcelLinkClicked(){
        this.showParcelsManagement = true;
      },
    },
    created () {
      this.showParcelsManagement = this.$route.query.showParcelsManagement == 'true';
      this.parcelToShow = this.$route.query.parcelToShow;
      console.log(' this.parcelToShow: ',  this.parcelToShow);
    },
  }
  </script>