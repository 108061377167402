<template>
    <div class="container" >
        <form @submit.prevent="login">
            <div class="formGroup">
                <label for="user_email">Email</label>
                <input type="text" id="user_email" v-model="user.email"/>
            </div>
            <div class="formGroup">
                <label for="user_password">Mot de passe</label>
                <input type="text" id="user_password" v-model="user.password"/>
            </div>
            <div class="formGroup">
                <button type="submit" class="button">Connexion</button>
            </div>
        </form>
        <LoadingSpinner v-if="isLoggingIn"></LoadingSpinner>
    </div>
</template>

<script>
import * as backend_services from '@/services/backend_services'
import LoadingSpinner from "@/components/animatedComponents/LoadingSpinner.vue"
import * as db_interaction from '@/services/db_interaction'

export default {
    components: {
        LoadingSpinner
    },
    name: 'Login',
    data(){
        return {
            user: {
                email:'',
                password:'',
            },
            isLoggingIn: false
        }
    },
    methods: {
        // checks if a spider model exists (by checking if there's a ref parcel)
        async existingSpiderModel() {
            const refParcel = await db_interaction.getRefParcelSpiderModel();
            if (refParcel == null) {
                localStorage.setItem('existingSpiderModel', false);
            } else {
                localStorage.setItem('existingSpiderModel', true);
                localStorage.setItem('refParcel', refParcel);
            }
        },
        async login(){
            localStorage.clear();
            this.isLoggingIn = true;
            let token = null;
            const res = await backend_services.login(this.user.email, this.user.password)
            this.isLoggingIn = false;
            if (res === undefined){
                console.log('not logged in');
            } else  {
                token = res['token'];
                console.log('token: ', token);
                localStorage.setItem('token', token);
                // check if existing spider model
                let existingModel = await this.existingSpiderModel();
                console.log('existingModel: ', existingModel);
                this.$router.push({ path: '/mapView'});
            }
        }
    }
}
</script>

<style scoped>
    form{
        max-width: 300px;
        margin: 0 auto;
        position: absolute;
        top: 30%;
        left: 40%;
    }
    .formGroup{
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        margin-left: 5px;
    }

    label{
        margin-right: 10px;
        font-weight: bold;
    }

    button{
        position: absolute;
        margin-top:20px;
        left: 55%;
    }

    input:hover{
        border: 2px solid #3498db; /* Change border color when hovering */
        background-color: #f2f2f2; /* Change background color when hovering */
        color: #333; /* Change text color when hovering */
    }

    input:focus {
        outline: none; /* Remove default focus outline */
        border: 1.5px solid #000000; /* Change border color when focused */
        background-color: #ecf0f1; /* Change background color when focused */
        color: #333; /* Change text color when focused */
    }
    
    button:hover{
        cursor: pointer;
        border: 2px solid #1f72aa; /* Change border color when focused */
        transform: scale(1.1);
    }

    div.container {
        position: fixed;
        left: 0;
        top:6vh;
        width: 100vw;
        height: 94vh;
        background-color: #5c7778;
        z-index: 0;
    }
</style>