<template>
    <div class="overlay">
        <div class="form">
            <span class="close" @click="closeWindowForm">&times;</span>
                <form @submit="submitForm">
                    <div class="refParcelsContainer">
                        <p class="subTitle"><b>Nouvelles observations</b></p>
                        <p><a href="refFile">Télécharger le fichier modèle</a></p>
                        <p> <input type="file" @change="newObservationsFile"> </p>
                    </div>
                <button class="button" type="submit" @click="submitForm">Valider</button>
            </form>
        </div>
        <div v-if="isDataLoading">
                <LoadingSpinner></LoadingSpinner>
        </div>
    </div>
</template>



<script>
import * as db_interaction from '@/services/db_interaction'
import LoadingSpinner from "@/components/animatedComponents/LoadingSpinner.vue"
import * as backend_services from '@/services/backend_services'

export default{
    components: {
        LoadingSpinner,
    },
    data(){
        return {
            isDataLoading: false,
            newReferencesMeasuresFile: null,
            formData:{
                    phfb: null,
                    date: null,
                    latitude: '44.0109336',
                    longitude: '4.685374'
                },
        };
    },
    props: {
        selectedParcel: String
    },
    methods: {
        closeWindowForm(){
            this.$emit('close-window-form')
        },
        async submitForm(e){
            e.preventDefault();
            this.isDataLoading = true;
            // const latLongParcel = await db_interaction.getLatLongParcel(this.selectedParcel);
            // await db_interaction.addMeasureParcel('PHFB', this.formData.phfb, this.selectedParcel, this.formData.date, latLongParcel['latitude'].replace(',','.'), latLongParcel['longitude'].replace(',','.'));
            await backend_services.importNewMeasures(this.newReferencesMeasuresFile);
            // this.$emit('new-measure-added', this.formData.date);
            this.isDataLoading = false;
            this.closeWindowForm();
        },
        newObservationsFile(e){
            this.newReferencesMeasuresFile = e.target.files[0];
        }
    },
    created(){
        console.log('selectedParcel', this.selectedParcel);
    }
}
</script>


<style scoped>
    .form{
        position: absolute;
        left: 45%;
        top: 25%;
        width: 45%; 
        height: 25%;  
        background-color: rgb(147, 157, 157);
        border-radius: 5px;
    }
    form{
        position: relative;
        width: 40%; 
        top: -18%;
        left: 35%;
    }
    .form-group{
        margin-bottom: 10px;
    }
    input {
        width: 100%; /* Use 100% width for the inputs to make them appear as a single column */
        padding: 0px; /* Adjust padding to control size */
    }
    .overlay{
        position: fixed;
        top: 6vh;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
        z-index: 1000;
    }
    .close {
        font-size: 45px;
        font-weight: 500;
        display: inline-block;
        position: relative;
        left: 2%;
        top: 0%;
        color:rgb(107, 102, 102);
    }
    .close:hover{
        cursor: pointer;
        color:rgb(51, 106, 87);
        transform:scale(1.1);
    }
    button{
        margin: 50px 60px;
    }

    .subTitle{
        margin-bottom: 20px;
    }
</style>