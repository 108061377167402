<template>
  <div>
    <canvas id="planet-chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment';
import * as lineChartDataFunctions from '@/components/configComponents/lineChartData.js'

export default {
  name: 'LineChart',
  data() {
    return {
      lineChartData: null,
    }
  },
  props: {
    phfbMeasures_2021: Array,
    phfbMeasures_2022: Array,
    phfbMeasures_2023: Array,
    icapexMeasures: Array,
  },
  created(){
    this.lineChartData = lineChartDataFunctions.getLineChartData(this.phfbMeasures_2021, this.phfbMeasures_2022, this.phfbMeasures_2023);
  },
  mounted() {
    const ctx = document.getElementById('planet-chart');
    const chartLine = new Chart(ctx, this.lineChartData);
  }
}
</script>