import {CONSTRAINT_CLASSES} from '@/constants/phfbConstraintClasses'

// get monday's date of the same week
export const getMondayDate = (current_date) => {
    let current_day = current_date.getDay();
    current_day = current_day == 0 ? 7 : current_day;// if current_day is Sunday (Sunday is 0 in js Date)
    // number of days to monday
    const nb_days_to_monday = 1 - current_day;
    // date of the monday of the same week
    const monday_of_same_week = new Date(current_date);
    monday_of_same_week.setDate(monday_of_same_week.getDate() + nb_days_to_monday);
    
    return monday_of_same_week;
};

// get sunday's date of the same week
export const getSundayDate = (current_date) => {
    let current_day = current_date.getDay();
    current_day = current_day == 0 ? 7 : current_day;// if current_day is Sunday (Sunday is 0 in js Date)
    // number of days to sunday
    const nb_days_to_sunday = 7 - current_day;
    // date of the sunday of the same week
    const sunday_of_same_week = new Date(current_date);
    sunday_of_same_week.setDate(sunday_of_same_week.getDate() + nb_days_to_sunday);

    return sunday_of_same_week;
}

    // compute date range according to currentDate and display mode (estimation or real-data) 
    // estimation -> measures of the day ; real-data -> measures of the week
    // returns: array [startDate, endDate]
    export const computeDateRangeOld = (current_date, dailyMeasuresDisplay) => {     
        let startDate = new Date(current_date);
        let endDate = new Date(current_date);
        
        // compute startDate and endDate
        if (dailyMeasuresDisplay) {// estimation-data display
          startDate = current_date;
          endDate = current_date;
        } else {// real-data display
          // get monday's date of the same week
          startDate = getMondayDate(current_date);
          // get sunday's date of the same week
          endDate = getSundayDate(current_date);
        } 
        
        // format start date (yyyy-mm-dd 00:00:00)
        let month = startDate.getMonth() >= 9 ? (startDate.getMonth() + 1).toString() : '0' + (startDate.getMonth() + 1).toString();
        let date = startDate.getDate() >= 9 ? startDate.getDate().toString() : '0' + startDate.getDate().toString();
        let year = startDate.getFullYear();
        const pStartDate = year + '-' + month + '-' + date + ' ' + '00:00:00';
       
        // format end date (yyyy-mm-dd 12:59:59)
        month = endDate.getMonth() >= 9 ? (endDate.getMonth() + 1).toString() : '0' + (endDate.getMonth() + 1).toString();
        date = endDate.getDate() >= 9 ? endDate.getDate().toString() : '0' + endDate.getDate().toString();
        year = endDate.getFullYear();
        const pEndDate = year + '-' + month + '-' + date + ' ' + '12:59:59';
        
        console.log('pStartDate ', pStartDate);
        console.log('pEndDate ', pEndDate);

        return [pStartDate, pEndDate];
    }

        // compute date range according to currentDate and display mode (estimation or real-data) 
    // estimation -> measures of the day ; real-data -> measures of the week
    // returns: array [startDate, endDate]
    export const computeDateRange = (current_date) => {     
      let startDate = new Date(current_date);
      let endDate = new Date(current_date);
      
      // format start date (yyyy-mm-dd 00:00:00)
      let month = startDate.getMonth() >= 9 ? (startDate.getMonth() + 1).toString() : '0' + (startDate.getMonth() + 1).toString();
      let date = startDate.getDate() >= 9 ? startDate.getDate().toString() : '0' + startDate.getDate().toString();
      let year = startDate.getFullYear();
      const pStartDate = year + '-' + month + '-' + date + ' ' + '00:00:00';
     
      // format end date (yyyy-mm-dd 12:59:59)
      month = endDate.getMonth() >= 9 ? (endDate.getMonth() + 1).toString() : '0' + (endDate.getMonth() + 1).toString();
      date = endDate.getDate() >= 9 ? endDate.getDate().toString() : '0' + endDate.getDate().toString();
      year = endDate.getFullYear();
      const pEndDate = year + '-' + month + '-' + date + ' ' + '12:59:59';
      
      console.log('pStartDate ', pStartDate);
      console.log('pEndDate ', pEndDate);

      return [pStartDate, pEndDate];
  }


// get a new date from a string of the form 'dd/mm/yyyy'
// export const getDateFromString = (string_date) => {
//   const parts = string_date.split('/');
//   return new Date(parts[1] + '/' + parts[0] + '/' + parts[2]);
// }

// turn a string date of the form 'dd/mm/yyyy' into the form 'mm/dd/yyyy'
export const changeStringDateFormat = (string_date) => {
  const parts = string_date.split('/');
  return parts[1] + '/' + parts[0] + '/' + parts[2];
}

// get string in the form (dd/mm/yyyy) from a date
// export const getStringFromDate = (pDate) => {
//   const date = pDate.getDate();
//   const month = pDate.getMonth();
//   const year = pDate.getFullYear();

  
//   return '';
// }


export const addDays = (p_date, nb_days) => {
  let newDate = new Date(p_date);
  console.log('date: ', p_date);
  newDate.setDate(p_date.getDate() + nb_days);
  return newDate;
}

// get the constraint class of the specified PHFB value according to the specified date of the year
export const getClassConstraintFromDate = (pDate, phfbValue) => {
  // get classes of specified date
  const pDay = parseInt(pDate.getDate());
  const pMonth =  parseInt(pDate.getMonth()) + 1;
  const date_classes = CONSTRAINT_CLASSES.find((elt) => {
    const classDay = parseInt(elt.date.split('/')[0]);
    const classMonth = parseInt(elt.date.split('/')[1]);
    if (classDay === pDay && classMonth === pMonth) {
      return true;
    }
  });
  
  // get classes values
  const class_B = Number(date_classes['B'].replace(',','.'));
  const class_C = Number(date_classes['C'].replace(',','.'));
  const class_D = Number(date_classes['D'].replace(',','.'));
  const class_E = Number(date_classes['E'].replace(',','.'));

  // determine class
  if (phfbValue <= class_E) {
    return 'E';
  } else {
    if (phfbValue <= class_D) {
      return 'D';
    } else {
      if (phfbValue <= class_C) {
        return 'C';
      } else {
        if (phfbValue <= class_B) {
          return 'B';
        } else {
          return 'A';
        }
      }
    }
  }
  
}