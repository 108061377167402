<template>
    <div class="container" >
        <span class="close" @click="close">&times;</span>
        <h2 class="mainTitle">Création d'un nouveau territoire</h2>
        <div >
            <div class="listContainer">
                <p class="subTitle">Choisissez les campagnes à partir desquelles étalonner le modèle Spider</p>
                    <li v-for="(item, index) in validSeasons" :key="index" @change="checkboxListClicked">
                        <input type="checkbox" :value="item"/>
                        {{ item }}
                    </li>
            </div>
            <div class="dropDownContainer">
                <p class="=subTitle">Choisissez la parcelle de référence</p>
                <select :disabled="chosenSeasons.length == 0" v-model="newRefParcel">
                    <option value="" disabled selected>Selectioner une parcelle </option>
                    <option v-for="parcel in candidateRefParcels" :value="parcel">{{ parcel }}</option>
                </select>
            </div>
            <div class="button">
                <button :disabled="newRefParcel == null || chosenSeasons.length == 0" type="submit" @click="clickButton">Etalonner le modèle Spider</button>
            </div>
        </div>
        <!-- <div v-else class="existingSpiderModel">
            <h2>{{ this.message }}</h2>
            <table>
                <thead>
                    <tr>
                    <th> Parcelle de référence </th>
                    <th> R2 </th>
                    </tr>
                </thead>
                <tbody>
                    <td>{{ this.refParcel }}</td>
                    <td> *** </td>
                </tbody>
            </table>
            <button class="newModel" @click="newModel">Nouveau modèle</button>

        </div> -->
    </div>
    <div v-if="isDataLoading">
      <LoadingSpinner></LoadingSpinner>
    </div>


</template>


<script>
import * as db_interaction from '@/services/db_interaction'
import LoadingSpinner from "@/components/animatedComponents/LoadingSpinner.vue"

export default{
    
    data () {
        return {
            isDataLoading: false,
            validSeasons: [],
            validParcels: [],
            candidateRefParcels: [],
            newRefParcel: null,
            refParcel: null,
            existingSpiderModel: false,
            chosenSeasons: [],
            coeffs: null,
            message:'',
        }
    },
    props: {
    },
    components: {
        LoadingSpinner
    },
    methods:{
        async newModel() {
            this.existingSpiderModel = false;
            this.refParcel = null;
            this.newRefParcel = null;
            this.$emit('new-ref-parcel', this.refParcel);
            await db_interaction.deleteSpiderModel();
        },
        async getSeasonsPHFB(){
            this.isDataLoading = true;
            // request seasons
            this.result = await db_interaction.getValidSeasonsSpiderModel(); // TODO: test and verify this function
            // await db_interaction.testingMethod();
            this.isDataLoading = false;
            this.validSeasons = this.result[0];
            this.validParcels = this.result[1];
            console.log('this.validSeasons: ', this.validSeasons);
            console.log(' this.validParcels: ',  this.validParcels);
        },
        checkboxListClicked(e) {
            console.log(e);
            // add checked season
            if (e.target.nodeName == 'INPUT' && e.target.checked) {
                this.chosenSeasons.push(e.target.value);
            } 
            // remove unchecked season
            if (e.target.nodeName == 'INPUT' && !e.target.checked) {
                this.chosenSeasons = this.chosenSeasons.filter(season => season != e.target.value);
            }
            console.log('chosen seasons: ', this.chosenSeasons);
            this.computecCandidateReferenceParcels();
        },
        // compute the parcels that can be used as reference parcels for the chosen seasons
        computecCandidateReferenceParcels(){
            // reset candidate reference parcels list
            while(this.candidateRefParcels.length > 0) {
                this.candidateRefParcels.pop();
            }
            // iterate over chosen seasons
            this.chosenSeasons.forEach((season) => {
                // iterate over valid parcels of the current season
                this.validParcels[season].forEach((parcel) => {
                    // add parcel to candidate reference parcels if not done already
                    if (!this.candidateRefParcels.includes(parcel)) {
                        this.candidateRefParcels.push(parcel);
                    }
                });
            });
            console.log('candidate ref parcels: ', this.candidateRefParcels);
        },
        async clickButton(){
            this.isDataLoading = true;
            // take off reference parcel from candidate parcels
            const candidateReferenceParcels = this.candidateRefParcels.filter(parcel => parcel != this.newRefParcel);
            // compute spider model coefficients
            this.coeffs = await db_interaction.computeSpiderCoefficients(this.chosenSeasons, candidateReferenceParcels, this.newRefParcel);   
            this.existingSpiderModel = true;
            this.isDataLoading = false;
            this.$emit('new-model-calculated', this.newRefParcel);
        },
        getMemoParams() {
            console.log('localStorage.getItem', localStorage.getItem('existingSpiderModel'));
            this.existingSpiderModel = (localStorage.getItem('existingSpiderModel') == null) ? false : localStorage.getItem('existingSpiderModel') == 'true' ? true : false;
            this.refParcel = localStorage.getItem('refParcel');
        },
        close(){
            this.$router.push('/mapView');
        }
    },
    created(){
        this.getMemoParams();
        console.log('existinggggggg', this.existingSpiderModel);
    },
    mounted(){
        this.getSeasonsPHFB();
    },

    beforeRouteLeave() {
        // vérifier valeur existingSpiderModel
        // console.log('iccccciiiiiiiiiiii: ', this.existingSpiderModel);
        // localStorage.setItem('existingSpiderModel', this.existingSpiderModel);
    }
}
</script>

<style scoped>
    div.container {
        position: fixed;
        left: 0vw;
        top:6vh;
        width: 100vw;
        height: 94vh;
        background-color: hsl(180, 30%, 89%);
        z-index: 0;
    }
    .listContainer {
        position: absolute;
        top: 10%;
        left: 5%;
    }
    .dropDownContainer{
        position: absolute;
        top: 25%;
        left: 5%;
        margin-left: 0;
        padding-left: 0;
    }
    .button{
        position: absolute;
        top: 37%;
        left: 45%;
    }
    button{
        height:30px;
        font-weight: bold;
    }
    button:hover:enabled{
        cursor: pointer;
    }
    li{
        list-style-type: none;
        margin: 0px 5px;
    }
    .newModel {
        margin-top: 20px;
        margin-left: 50px;
    }

    .existingSpiderModel{
        position: absolute;
        top: 40%;
        left: 40%;
    }

    /* styling for the grid */
    table {
      border-collapse: collapse;
      width: 100%;
      border: 2px solid #ddd; /* Add border to the table */
    }
    th, td {
      border: 1px solid #ddd; /* Add border to table cells */
      padding: 8px;
      text-align: left;
    }
    th {
      background-color: #f2f2f2;
    }
    h2{
        margin-bottom: 20px;
        margin-left: 10px;
    }
    .mainTitle{
        text-align: center;
        padding-bottom: 20px;
    }
    .subTitle{
        margin-left: 0;
        padding-left: 0;
        position: relative;
        left: 0px;
    }
    li{
        margin-left: 0;
        padding-left: 0;
    }
    .close {
        font-size: 45px;
        font-weight: 500;
        display: inline-block;
        position: absolute;
        left: 1%;
        top: 0%;
        color:rgb(107, 102, 102);
    }
    .close:hover{
        cursor: pointer;
        color:rgb(51, 106, 87);
        transform:scale(1.1);
    }
</style>