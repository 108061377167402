export default class Measure {
    constructor(id, parcelName, value, measureType, type, date, reliability, latitude, longitude, constraintClass){
        this.id = id;
        this.parcelName = parcelName;
        this.value = value;
        this.measureType = measureType;// estimation or measure
        this.type = type;// PHFB, IC-APEX...
        this.date = date;
        this.reliability = reliability;
        this.latitude = latitude;
        this.longitude = longitude;
        this.constraintClass = constraintClass;
    }
};