<template>
    <div class="sidebar">
        <!-- <MenuButton @menu-button-clicked="menuButtonClicked"></MenuButton> -->
        <span class="close" @click="closeConfigSideBar($event)">&times;</span>
        <div class="link-list">
            <div class="link">
                <label class="parcels" @click="parcelLinkClicked">
                    <img src="images/parcel.svg"> 
                    <span>Gérer mes parcelles</span>
                </label>
            </div>
            <!-- <div v-if="user == 'technician'" class="link">
                <label class="spiderModel" @click="spiderModelClicked">
                    <img src="images/settings.svg"> 
                    <span>Etalonner le modèle spider</span>
                </label>
            </div> -->
            <!-- <div v-if="!refParcel == ''" class="link">
                <label class="addRefParcelMeasure" @click="addRefParcelMeasureClicked">
                    <img src="images/addButton.svg"> 
                    <span>Ajouter mesure parcelle référence</span>
                </label>
            </div> -->
        </div>
    </div>
</template>

<script>
import MenuButton from '@/components/animatedComponents/MenuButton.vue'

export default{
    props: {
        user: String,
        refParcel: String,
        showParcelsManagement: Boolean
    },
    data () {
        return {
        }
    },
    components: {
        MenuButton,
    },
    methods:{
        closeConfigSideBar(){
            this.$emit('close-config-side-bar');
        },
        parcelLinkClicked() {
            this.$router.push({ path: '/parcelsView', query: {showParcelsManagement: true}});
            this.$emit('parcel-link-clicked');
        },
        spiderModelClicked() {
            this.$router.push({ path: '/configView', query: {showParcelsManagement: false}});
            this.$emit('spider-model-link-clicked');
        }
    },
    created(){
        console.log("refParcel test: ", this.refParcel);
    }
}
</script>

<style scoped>
    .sidebar {
        height: 94vh;
        width: 20vw;
        background-color: rgb(204, 226, 245);
        color: rgb(0, 0, 0);
        position: fixed;
        top: 6vh;
        bottom: 30px;
        left: 0;
        overflow-x: hidden;
        box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.3); /* Offset-x, Offset-y, Blur-radius, Color */
    }
    label{
        display: flex;
        align-items: center;
    }
    .parcels img {
        width: 50px;
        height: 50px;
        margin-right: 20px;
    }
    .parcels span {
        top: 0px;
        padding-top: 10px;
    }
    .spiderModel img {
        width: 40px;
        height: 40px;
        margin-right: 30px;
    }
    .addRefParcelMeasure img{
        width: 25px;
        height: 25px;
        margin-right: 30px;
        margin-left: 12px;
    }
    .link{
        margin: 50px 10px;
    }
    label:hover{
        color: rgb(41, 147, 239);
        cursor: pointer;
        font-weight: bold;
        transform: scale(1.01);
        text-decoration: underline;
    }

    .close {
        font-size: 45px;
        font-weight: 500;
        display: inline-block;
        position: relative;
        left: 2%;
        top: 0%;
        color:rgb(107, 102, 102);
    }

    .close:hover{
        cursor:pointer;
        color:rgb(41, 147, 239);
        transform:scale(1.15);
    }
</style>