import { createRouter, createWebHistory } from 'vue-router'
import MapView from '../views/MapView.vue'
import ConfigView from "../views/ConfigView.vue"
import LoginView from "../views/LoginView.vue"
import ParcelsView from '@/views/ParcelsView.vue'

const routes = [
  {
    // path: '/mapView/:paramTest',
    path: '/mapView',
    name: 'MapView',
    component: MapView
  },
  {
    path: '/',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/configView',
    name: 'ConfigView',
    component: ConfigView
  },
  {
    path: '/parcelsView',
    name: 'parcelsView',
    component: ParcelsView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
