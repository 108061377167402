<template>
    <div class="sidebar">
        <MenuButton @menu-button-clicked="menuButtonClicked"></MenuButton>
        <div class="list">
            <div class="territory-section">
                <span>Territoire</span>
                <div class="">
                    <select v-model="this.territory">
                        <!-- <option value="" disabled selected>Selectioner une parcelle </option> -->
                        <option v-for="terr in this.territories" :value="terr">{{ terr }}</option>
                    </select>
                    <button @click="newTerritory">+</button>
                </div>
            </div>
            <div v-if="existingSpiderModel" class="spider-section">
                <label class="switch">
                    <input type="checkbox" class="on" checked disabled>
                    <span class="slider round "></span>
                </label>
                <span> &nbsp;  Modèle spider paramétré</span>
            </div>
            <!-- <div v-if="existingSpiderModel" class="ref-parcel-section">
                <span>&rarr; &nbsp; Parcelle référence : {{ refParcel }}</span>
            </div> -->
            <!-- <div v-if="existingSpiderModel" class="water-balance-section">
                <label class="switch">
                    <input type="checkbox" disabled>
                    <span class="slider round "></span>
                </label>
                <span> &nbsp; Bilan hydrique</span>
            </div> -->
            <div v-if="existingSpiderModel" class="new-obs-section">
                    <button @click="newMeasure">+</button> <span>Nouvelles observations</span>
            </div>
        </div>
    </div>
</template>

<script>
import Tabs from '@/components/mapComponents/Tabs.vue'
import MenuButton from '@/components/animatedComponents/MenuButton.vue'

export default{

    
    data () {
        return {
            phfb_checkbox: false,
            icapex_checkbox: false,
            ru_checkbox: false,
            bilan_hydrique_checkbox: false,
            territory: 'pour voir',
            territories: []
        }
    },
    props: {    
        refParcel: String,
        existingSpiderModel: Boolean
    },
    components: {
        Tabs,
        MenuButton,
    },
    methods:{
        // callback function to react to checkbox clicked
        checkboxClicked(e){
            // emit event with layers to display on map (according to checked boxes)
            this.$emit('checkbox-clicked', e);
        },
        toggleTabButton(){
            this.$emit('toggle-tab-button');
        },
        menuButtonClicked(){
            this.$emit('menu-button-clicked');
        },
        newTerritory(){
            this.$router.push({ path: '/configView'});
        },
        newMeasure(){
            this.$emit('new-ref-parcel-measure');
        },
        newBilanHydrique(){
            this.$emit('new-ref-parcel-bilan-hydrique');
        }
    },
    created(){
        this.phfb_checkbox = (localStorage.getItem('display_phfb') === null || localStorage.getItem('display_phfb') === 'none' ) ? false : true;
        this.icapex_checkbox = (localStorage.getItem('display_ic_apex') === null || localStorage.getItem('display_ic_apex') === 'none' ) ? false : true;
    },

    mounted(){
        this.territory = localStorage.getItem('territory');
        this.territories.push(this.territory);
        console.log('mounted territory: ', localStorage.getItem('territory'));
    }

}
</script>

<style scoped>
    .sidebar {
        height: 94vh;
        width: 20vw;
        background-color: rgb(222,235,247);
        color: white;
        position: fixed;
        top: 6vh;
        bottom: 30px;
        left: 0;
        overflow-x: hidden;
        box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.3); /* Offset-x, Offset-y, Blur-radius, Color */
    }
    .list{
        position: relative;
        top: 10%;
        left: 5%;
        color: black;
        background-color: rgb(204, 226, 245);
        padding: 10px 0px;
        width: 18vw;
        display: flex;
        flex-direction: column; /* Display children vertically */
    }
    .territory-section{
        margin: 15px;
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
    }

    .spider-section{
        margin: 15px;
        display: flex;
        margin-bottom: 20px;
        color: green;
    }

    .water-balance-section{
        margin: 15px;
        display: flex;
        margin-bottom: 50px;
        color: rgb(144, 13, 13);
    }


    /* .water-balance-section input:checked::after{
        content: "X";
        color: #ffffff;
        background: red;
        font-size: 15px;
    } */

    .ref-parcel-section{
        margin-left: 50px;
        font-size:small;
        margin-bottom: 30px;
    }

    .new-obs-section{
        margin-left: 6px;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .new-obs-section span {
        margin-left: 40px;
        margin-top: 3px;
    }



    /* label:hover{
        color: rgb(41, 147, 239);
        cursor: pointer;
        font-weight: bold;
    } 

    input:hover{
        cursor: pointer;
        border-radius: 10px;
        border-color: blue;
        accent-color: blue;
        border-width: 5px;
        color: blue;
        transform:scale(1.1);
    }  */

    /* style of the text associated to the checkbox */
    /* input:checked + span {
        color: rgb(41, 147, 239);
        font-weight: bold;
    } */

    .container{
        display: flex;
        flex-direction:row;
    } 

    select{
        width: 70%;
    }

    button{
        position: absolute;
        width: 22px;
        height: 22px;
        margin-left: 10px;
        padding: 0px;
        font-size:large;
    }

    button:hover{
        cursor:pointer;
    }

    .crossed-checkbox{
        text-decoration:aliceblue
    }

/* toggle button */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.slider:hover{
    cursor: default;
}

.switch input { 
  opacity: 0;
  width: 10px;
  height: 10px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: -2px;
  left: 0px;
  right: 0;
  bottom: 0;
  background-color: #d91e1e7d;
  -webkit-transition: .4s;
  transition: .4s;
}

.on{
    left: 4px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #27c105a0;
}

input:focus + .slider {
  box-shadow: 0 0 1px #28c105;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

</style>